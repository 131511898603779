<template>
    <div class="parameter" :class="fieldGroup__class">
        <span class="parameter__icon icon icon_type_circle"
              :class="fieldGroup_icon_class"
              v-if="icon"
        >
            <span class="icon__symbol"><SvgIcon class="icon" :name="icon"/></span>
        </span>
        <h5 class="parameter__label" :class="fieldGroup_label_class">{{ label }}</h5>
        <p class="parameter__value" :class="fieldGroup_value_class">
            {{ value }}
            <button class="parameter__button button button_view_icon button_theme_primary"
                    @click="$emit('fieldFn')"
                    v-if="!(!buttonExists)"
            >
                <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" :name="value_button_icon"/></span> </span>
            </button>
        </p>
    </div>

</template>

<script>
export default {
    name: "FieldGroup_info",
    emits:['fieldFn'],
    props: {
        'fieldGroup_class': {
            type: [String, Array, Object],
            default: () => ''
        },
        'fieldGroup_icon_class': {
            type: [String, Array, Object],
            default: () => ''
        },
        'fieldGroup_label_class': {
            type: [String, Array, Object],
            default: () => ''
        },
        'fieldGroup_value_class': {
            type: [String, Array, Object],
            default: () => ''
        },
        'fieldGroup_value_button_class': {
            type: [String, Array, Object],
            default: () => ''
        },
        'label': String,
        'value': [Number, String, Array, Object],
        'icon': String,
        'buttonExists': [Boolean],
        'value_button_icon': String,
    },
    data() {
        return {}
    },
    computed: {
        fieldGroup__class() {
            let fieldGroup_class = this.fieldGroup_class
            let type = (typeof this.fieldGroup_class)
            if (type === 'string') {
                return fieldGroup_class
                // + ((this.error > '') ? ' has-error ' : '')
                // + ((this.disabled === true) ? ' is_disabled ' : '')
            } else
            if (type === 'object') {
                if (Array.isArray(this.fieldGroup_class)) {
                    // if (this.error > '') fieldGroup_class.push('has-error')
                    // if (this.disabled === true) fieldGroup_class.push('is_disabled')
                    return fieldGroup_class
                } else {
                    return {
                        // 'is_disabled': (this.disabled === true),
                        // 'has-error': (this.error > ''),
                        ...fieldGroup_class
                    }
                }
            }
            //console.error('FieldGroup_info::fieldGroup__class', type, fieldGroup_class)
            return this.fieldGroup_class
        },
    },
    methods: {
        copy() {
            //console.log('copy')
        }
    },
    mounted() {
    },
    updated() {
        //console.log('FieldGroup_input updated', this.id, this.error)
    },
}
</script>

<style scoped>

</style>