<template>
    <FieldGroup__base_wrap
        :fieldGroup_class="fieldGroup_class"
        :fieldGroup_label_class="fieldGroup_label_class"
        :fieldGroup_info_class="fieldGroup_info_class"
        :id="id"
        :label="label"
        :labelIcon="labelIcon"
        :info="info"
        :error="error"
        :disabled="disabled"
    >
        <timepicker class="kendo-timepicker"
                    :min="min"
                    :max="max"
                    :format-placeholder="'formatPattern'"
                    :format="format"
                    :disabled="disabled"
                    v-model="time"
        />
    </FieldGroup__base_wrap>
</template>
<script>

import {TimePicker} from '@progress/kendo-vue-dateinputs';
//import {provideIntlService} from '@progress/kendo-vue-intl';
import moment from "moment";
import {mapGetters} from "vuex";
const datetime_format = 'YYYY-MM-DD HH:mm:ss'
const time_format = 'HH:mm:ss'

export default {
    name: 'FieldGroup_kendo_timepicker',
    emits: ['update:modelValue'],
    props: {
        'fieldGroup_class': {
            type: [String, Array, Object],
            default: () => ''
        },
        'fieldGroup_label_class': {
            type: [String, Array, Object],
            default: () => ''
        },
        'fieldGroup_input_class': {
            type: [String, Array, Object],
            default: () => ''
        },
        'fieldGroup_info_class': {
            type: [String, Array, Object],
            default: () => ''
        },
        'id': String,
        'label': {
            type: String,
            default: () => {
                return ''
            }
        },
        labelIcon: String,
        'modelValue': [String],
        'placeholder': String,
        'error': {
            type: [String, Array, Object],
            default: () => ''
        },
        'info': String,
        'input_icon': String,
        'maxTime': [String],//'YYYY-MM-DD HH:mm:ss'
        'minTime': [String],//'YYYY-MM-DD HH:mm:ss'
        'format': {
            type: [String],
            default: () => time_format
        },
        'disabled': {
            type: [Boolean],
            default: () => false
        }
    },
    components: {
        'timepicker': TimePicker
    },
    data() {
        return {
            datetime_format,
            time_format,
        }
    },
    computed: {
        ...mapGetters([
        ]),
        min() {
            return new Date(this.minTime)
            // return provideIntlService(this).parseDate(this.minTime, 'yyyy-MM-dd HH:mm:ss')
        },
        max() {
            return new Date(this.maxTime)
            // return provideIntlService(this).parseDate(this.maxTime, 'yyyy-MM-dd HH:mm:ss')
        },
        time: {
            get() {
                return new Date(this.modelValue)
                // return provideIntlService(this).parseDate(this.modelValue, 'yyyy-MM-dd HH:mm:ss')
            },
            set(val) {
                val = moment(val.toISOString()).format(datetime_format)
                //val = provideIntlService(this).toString(val, 'yyyy-MM-dd HH:mm:ss')
                if(val < this.minTime || val > this.maxTime){
                    val = ''+this.modelValue
                }
                this.$emit('update:modelValue', val)
            }
        }
    },
    methods: {},
    created() {
    },
    mounted() {
    },
    updated() {
    },
}
</script>
