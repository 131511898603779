const RESOURCE_NAME = '';//'/auth/';
import axios from "@/api/http-auth";
import config from "@/config";
const baseURL = config.api.baseURL

export default () => ({
    login(data, params) {
        return axios.post(`${RESOURCE_NAME}/login`, data, {params, baseURL});
    },
    loginAs(data, params) {
        return axios.post(`${RESOURCE_NAME}/login-as`, data, {params, baseURL});
    },
    check(params) {
        return axios.get(`${RESOURCE_NAME}/`, {params, baseURL});
    },
    refreshTokens(params) {
        return axios.get(`${RESOURCE_NAME}/refresh-token`, {params, baseURL});
    },
    logout(data, params) {
        return axios.post(`${RESOURCE_NAME}/logout`, data, {params, baseURL});
    },
});