// import store from '@/store';
// import moment from "moment";
import consts from "@/consts";
const routes = (process.env.VUE_APP_PACKAGE !== 'prometheus.portal') ? [] : [
    {
        path: '/dashboard',
        name: consts.routerNames.dashboard.main,
        meta: {
            mainRouteName: consts.routerNames.dashboard.main,
            hideMap: true,
            pageLayout: 'section_type_statistics'
        },
        component: () => import('@/views/prometheus/portal/DashboardStatistics.vue')
    },
];

export default routes;