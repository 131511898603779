const RESOURCE_NAME = '/locator';

export default axios => ({
    getToken(data, params){
        return axios.post(`${RESOURCE_NAME}/token`, data, {params});
    },
    getUnit(data, params){
        return axios.post(`${RESOURCE_NAME}/unit`, data, {params});
    },
    getAppUser(data, params){
        return axios.post(`${RESOURCE_NAME}/app-user`, data, {params});
    },
    getUnitLmsg(data, params){
        return axios.post(`${RESOURCE_NAME}/unit-lmsg`, data, {params});
    },
    getUnitWeather(data, params){
        return axios.post(`${RESOURCE_NAME}/unit-weather`, data, {params});
    },
    checkToken(data, params){
        return axios.post(`${RESOURCE_NAME}/check`, data, {params});
    },
});