<template>
    <section
        class="section section-popup"
        :class="{'section-popup--open': showSectionPopup}"
    >
        <section class="section section-popup__wrap" :class="sectionPopup_class">
            <div class="table-command-body__wrap popup-header__wrap" v-if="showSectionPopup && sectionPopup_title">
                <div class="popup-header" :class="titlePosition">
                    <div class="popup-header-item">
                        <h2>{{ sectionPopup_title }}</h2>
                    </div>
                    <div class="popup-header-item popup-close">
                        <button class="button button_view_icon button_theme_primary" @click="onClose">
                            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__close"/></span> </span>
                        </button>
                    </div>
                </div>
            </div>
            <component :is="sectionPopup_component" :wrapper="'EditWizard'" v-bind="sectionPopup_props"  v-on:close-dialog="onClose" :class="'popup-body'" />
        </section>
    </section>
</template>

<script>
import {computed, defineAsyncComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import emptySection from "@/components/app/emptySection.vue";
// import SectionLoading from '@/components/_base/SectionLoading.vue'

export default {
    name: "SectionPopup",
    props:{},
    components:{
        emptySection,
        'UnitEditWizard': defineAsyncComponent(() => import('@/components/units/sectionInfo/UnitEdit.vue')),
        'UserEditWizard': defineAsyncComponent(() => import('@/components/users/sectionInfo/UserEdit.vue')),
        'UserEditWizardDNS': defineAsyncComponent(() => import('@/components/users/sectionPopup/WizardDnsSetup.vue')),
        'VideoEventView': defineAsyncComponent(() => import('@/components/video_events/sectionInfo/VideoEventView.vue')),
        'ObjectsChanges': defineAsyncComponent(() => import('@/components/objects_audit/objects/ObjectsChangesTableTreeList.vue')),
        'UserActions': defineAsyncComponent(() => import('@/components/objects_audit/objects/UserActionsTableTreeList.vue')),
        'statementInfo': defineAsyncComponent(() => import('@/components/subscriptions/tables/statementInfo.vue')),
        //defineAsyncComponent({
        //     loader: () => import('component'),
        //     loadingComponent: SectionLoading,
        //     delay: 10000 /* delay in ms before showing loading component */,
        //     timeout: 10000 /* timeout after this many ms */,
        // }),
    },
    data() {
        return {
            popup: {
                title: '',
                subtitle: '',
            }
        }
    },
    provide(){
        return{
            popup: computed(() => this.popup),
        }
    },
    computed:{
        ...mapGetters([
            "showSectionPopup",
            "getSectionPopup",
        ]),
        sectionPopup() {
            return (this.getSectionPopup || {component: 'emptySection', props: {}})
        },
        sectionPopup_component() {
            return this.getSectionPopup?.component || 'emptySection'
        },
        sectionPopup_props() {
            return this.getSectionPopup?.props || {}
        },
        sectionPopup_title() {
            return (this.getSectionPopup?.title ||'') + (this.popup.title + (this.popup.subtitle ? ' / ' + this.popup.subtitle : '') || '')
        },
        sectionPopup_class(){
            return this.getSectionPopup?.class || ''
        },
        titlePosition(){
            return 'popup-header__' + (this.getSectionPopup?.title ? 'left' : 'center')
        },
    },
    methods:{
        ...mapActions([
            "setSectionPopup"
        ]),
        onClose(){
            this.setSectionPopup(null)
            this.popup = {subtitle: '',title: ''}
        },
    },
    updated() {
        //console.log('SectionPopup updated', this.sectionPopup_component);
    },
    mounted() {
        // console.log('section_type_info', this)
    },
}
</script>

<style scoped>

</style>