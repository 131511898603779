<template>
    <div class="status-icon" :class="[statusClass, positionClass]">
        <SvgIcon name="video__camera" class="icon status-icon__icon" :style="{width: 8 +'px', height: 6 +'px'}"/>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "UnitsList_unit_video_status",
    props: {
        'unitId': [Number, String],
        'position':{
            type: String,
            default: () => { return 'tl' }
        }
    },
    components: {
    },
    data() {
        return {
            statuses: {
                online: 'ok',
                offline: 'error',
                standby: 'warning',
            }
        }
    },
    computed: {
        ...mapGetters([
            "unitsByIds",
            "unitsLmsgsByIds",
            "getVideoStatusesByUnitsIds",
        ]),
        unit() {
            return this.unitsByIds[this.unitId]
        },
        unitLmsg(){
            return this.unitsLmsgsByIds[this.unitId]
        },
        hasVideo(){
            return !!this.unit?.video
        },
        videoStatus(){
            let status = this.getVideoStatusesByUnitsIds[this.unitId]?.status
            return this.statuses[status] || status || ''
        },
        statusClass(){
            return 'status-icon__'+(this.videoStatus || 'null')
        },
        positionClass(){
            return 'status-icon__position-' + this.position
        }
    },
}
</script>

<style scoped>

</style>