const RESOURCE_NAME = '/shipment-tive';
export default axios => ({
    shipmentTrack(id, params) {
        return axios.get(`${RESOURCE_NAME}/${id}/tracks`, {params});
    },
    shipmentTrackPeriod(id, from, to, params) {
        return axios.get(`${RESOURCE_NAME}/${id}/tracks/${from}/${to}`, {params});
    },
    shipmentTrackDate(id, date, params) {
        return axios.get(`${RESOURCE_NAME}/${id}/tracks/${date}`, {params});
    }
});