<template>
    <section
        class="section section_type_wrapper-curtain"
        :class="{'section_status_open-widget main-widget': showSectionWidget,}"
    >
        <section class="section section_type_widget is_new-design">
            <component :is="sectionWidget_component" v-bind="sectionWidget_props"  v-on:close-dialog="onClose" />
        </section>
    </section>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import {mapActions, mapGetters} from "vuex";
import emptySection from "@/components/app/emptySection.vue";
import UserSettings from "@/components/app/sectionInfo/UserSettings.vue";
// import UserChangePassword from "@/components/app/sectionInfo/UserChangePassword.vue";
// import Payment from "@/components/app/sectionInfo/Payment.vue"

export default {
    name: "SectionWidget",
    props: [],
    components: {
        emptySection,
        UserSettings,
        'UserChangePassword':  defineAsyncComponent(() => import('@/components/app/sectionInfo/UserChangePassword.vue')),
        'UserUpgrade':  defineAsyncComponent(() => import('@/components/app/sectionInfo/UserUpgrade.vue')),
    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters([
            "showSectionWidget",
            "getSectionWidget",
        ]),
        sectionWidget() {
            return (this.getSectionWidget || {component: 'emptySection', props: {}})
        },
        sectionWidget_component() {
            return this.getSectionWidget ? this.getSectionWidget.component : 'emptySection'
        },
        sectionWidget_props() {
            return this.getSectionWidget ? this.getSectionWidget.props : {}
        },
    },
    methods: {
        ...mapActions([
            "setSectionWidget",
        ]),
        onClose(){
            this.setSectionWidget(null)
        },
    },
    updated: function() {
        //console.log('SectionWidget updated', this.sectionWidget_component);
    },
    mounted() {
        // console.log('section_type_info', this)
    },
}
</script>

<style scoped>

</style>