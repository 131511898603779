<template>
    <UnitInfo__sensors_template
        :class="classForValue"
        :unitId="unitId"
        :icon="icon"
        :title="$t('Unit.sensors.Battery')"
        :statusClass="statusClass"
        :statusIcon="statusIcon"
        :value="_value"
        v-if="_showByValue"
    />
</template>

<script>
import UnitInfo__sensors_template from "@/components/units/sensors/UnitInfo__sensors_template.vue"
import {mapGetters} from "vuex";
import {isValueExist} from "@/lib/lib";
export default {
    inject: ['sensorsValues'],
    props:{
        unitId: [String, Number],
        showIcon: {
            type: Boolean,
            default: () => true
        },
        showByValue: {
            type: Boolean,
            default: () => false,
        }
    },
    components:{
        UnitInfo__sensors_template,
    },
    name: "UnitInfo__sensors_battery",
    data() {
        return {
        }
    },
    computed:{
        ...mapGetters([
            'unitsLmsgsByIds',
        ]),
        unitLmsg() {
            return this.unitsLmsgsByIds[this.unitId]
        },
        unitLmsgSensors() {
            return this.unitLmsg?.sensors || {}
        },
        value() {
            return this.unitLmsgSensors?.battery?.text//this.$t('Not defined')
        },
        isValueExist() {
            return isValueExist(this.value)
        },
        _value() {
            return this.isValueExist ? this.value : '-'
        },
        classForValue() {
            return this.isValueExist ? this.$consts.classSensors.value : this.$consts.classSensors.no_value
        },
        _showByValue() {
            return this.showByValue ? this.isValueExist : true
        },
        statusClass(){
            return ''
        },
        statusIcon(){
            return ''
        },
        icon() {
            return this.showIcon ? 'unit_dashboard__battery' : false
        }
    },
    watch: {
        value: {
            handler: function (val) {
                if(this.sensorsValues) {
                    this.sensorsValues.battery = val
                }
            },
            immediate: true
        },
    },
    mounted() {
    },
    beforeUnmount() {
        if(this.sensorsValues) {
            delete this.sensorsValues.battery
        }
    }
}
</script>

<style scoped>

</style>