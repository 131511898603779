import consts from "@/consts"
import {updateObjectByDiff, getApiProps} from "@/lib/lib";
// import {objectComparison} from "@/lib/lib";
const changedField = 'changed__time';
const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        notificationsGroups: [],

        notificationsFullLoad: false,
        notifications: [],
    },
    actions: {
        fetchNotificationsGroups({commit, dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchNotificationsGroups', time: Date.now() / 1000})

                const params = getApiProps('notifications_groups', args)
                this.$api.notifications.getAllGroups(params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateNotificationsGroups', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchNotificationsGroups', inprogress: false})
                    });
            })
        },

        fetchNotifications/*all*/({dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchNotifications', time: Date.now() / 1000})

                const params = getApiProps('notifications', args)
                this.$api.notifications.getAll({...params})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchNotifications', inprogress: false})
                    });
            })
        },
        fetchNotificationsAll({dispatch, commit, getters}) {
            //dispatch('setLastCall', {name: 'fetchNotificationsAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchNotificationsChanged', time: Date.now() / 1000})

            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(null)
                }
                dispatch('fetchNotifications', {lite: true})
                    .then((data) => {
                        commit('setNotifications', data)
                        commit('setNotificationsFullLoad', true)
                        resolve(data.length)
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchNotificationsAll', {})
                        }, 60 * 1000)
                    })
            })
                .finally(() => {
                    //dispatch('setLastCall', {name: 'fetchNotificationsAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchNotificationsChanged', inprogress: false})
                });
        },
        fetchNotificationsChanged({dispatch, commit, getters}, args) {
            if (!getters.apiToken || !getters.isNotificationsFullLoad) {
                return
            }
            dispatch('setLastCall', {name: 'fetchNotificationsChanged', time: Date.now() / 1000})

            args = {...consts.querySettings.filter, ...args}
            return dispatch('fetchNotifications', args)
                .then((data) => {
                    commit('updateNotifications', data)
                    return dispatch('fetchNotifications', {fields: 'id', expand: ''})
                })
                .then((data) => {
                    commit('filterNotifications', data)
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchNotificationsChanged', inprogress: false})
                });
        },
        reloadNotificationsAll({commit, dispatch}, args) {
            commit('clearNotifications')
            return dispatch('fetchNotificationsAll', args)
                .then(() => {
                    //dispatch('fetchNotificationsAllPages', args)
                })
        },

        saveNotificationGeozone(context, args) {
            return new Promise((resolve, reject) => {
                let action = (args.enabled === true) ? 'add' : 'remove'
                this.$api.notifications.patchGeozones(args.notifId, action, {id_geozone: args.id_geozone})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                        //resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        //sayHello() {}
    },
    mutations: {
        setNotificationsFullLoad(state, FullLoad) {
            state.notificationsFullLoad = state.notificationsFullLoad || FullLoad
        },
        setNotifications(state, nNotifs) {
            nNotifs = nNotifs.map(u => {
                if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                return u //Object.freeze(u)
            })
            nNotifs.sort(sortByName)
            state.notifications = nNotifs
        },
        updateNotifications(state, nNotifs) {
            if (!state.notifications.length) {
                nNotifs = nNotifs.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nNotifs.sort(sortByName)
                state.notifications = nNotifs
                // const chunks = arraySplitIntoChunks(nNotifs)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.notifications.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nNotifs.forEach(nNotif => {
                if (nNotif?.name) nNotif.name_ = nNotif.name.toLocaleLowerCase()
                let i = state.notifications.findIndex(oNotif => oNotif.id == nNotif.id)
                if (i < 0) {
                    state.notifications.push(nNotif)
                } else
                if (!state.notificationsFullLoad || state.notifications[i][changedField] != nNotif[changedField]) {
                    updateObjectByDiff(state.notifications[i], nNotif)
                    // delete nNotif.id
                    // state.notifications[i] = {...state.notifications[i], ...nNotif}
                }
            })
        },
        filterNotifications(state, notifications) {
            let nIds = notifications.map(u => u.id)
            let removedIds = state.notifications.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.notifications.findIndex(u => (u.id == removedId))
                if (i != -1) {
                    state.notifications.splice(i, 1)
                }
            })
        },
        clearNotifications(state) {
            state.notifications = []
            state.notificationsFullLoad = false
        },
    },
    getters: {
        isNotificationsFullLoad(state) {
            return state.notificationsFullLoad
        },

        notifications(state) {
            return state.notifications
        },
        notificationsByIds(state) {
            return state.notifications.reduce((notificationsByIds, notification) => {
                notificationsByIds[notification.id] = notification
                return notificationsByIds
            }, {})
        },
    }
}
