<template>
    <div class="symbol__moving-status">
        <span class="symbol__bg-icon icon">
            <span class="icon__symbol"><SvgIcon class="icon" :name="params.bg_icon"/></span>
        </span>
        <span class="icon" :class="params.icon_class">
            <span class="icon__symbol"><SvgIcon class="icon" :name="params.icon"/></span>
        </span>
    </div>
</template>
<script>
    import {mapGetters} from "vuex";

    export default {
        name: 'UnitsList_unit_moving_status',
        props: [
            'unitId',
        ],
        data() {
            return {
                moving: {
                    bg_icon: 'units__moving_bg',
                    icon_class: 'symbol__moving-icon',
                    icon: 'units__moving_symbol',
                },
                parking: {
                    bg_icon: 'units__parking_bg',
                    icon_class: 'symbol__parking-icon',
                    icon: 'units__parking_symbol'
                }
            }
        },
        computed: {
            ...mapGetters([
                "unitsLmsgsByIds",
            ]),
            unitLmsg(){
                return this.unitsLmsgsByIds[this.unitId]
            },
            isMoving(){
                return this.unitLmsg?.status?.isMoving
            },
            params(){
                return this.isMoving ? this.moving : this.parking
            },
        },
        updated: function() {
           // console.log(`UnitsList_unit_moving_status ${this.isMoving} updated`);
        },
    }
</script>