const RESOURCE_NAME = '/exports';

export default axios => ({
    build(type, format, data, params) {
        return axios.post(`${RESOURCE_NAME}/${type}/${format}`, data, {params});
    },
    get4Date(type, unitId, date, format, params) {
        return axios.get(`${RESOURCE_NAME}/${type}/${unitId}/${date}/${format}`, {params});
    },
    get4Period(type, unitId, from, to, format, params) {
        return axios.get(`${RESOURCE_NAME}/${type}/${unitId}/${from}/${to}/${format}`, {params});
    },
});