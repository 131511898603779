// import Vue from 'vue'
import consts from "@/consts"
import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";
// import {objectComparison} from "@/lib/lib";
const changedField = 'changed__time';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        geopointsGroupsLiteLoad: false,
        geopointsGroupsFullLoad: false,
        geopointsGroups: [],
    },
    actions: {
        fetchGeopointsGroups/*all*/({dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchGeopointsGroups', time: Date.now() / 1000})

                const params = getApiProps('geopoints_groups', args)
                this.$api.geopointsgroups.getAll({...params})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchGeopointsGroups', inprogress: false})
                    });
            })
        },
        fetchGeopointsGroupsAll({dispatch, commit}) {
            return new Promise((resolve, reject) => {
                this.$api.init.getGeopointsGroups()
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {

                            commit('setGeopointsGroups', response.data)
                            commit('setGeopointsGroupsLiteLoad', true)

                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchGeopointsGroupsAll', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchGeopointsGroupsAllLite({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isGeopointsGroupsLiteLoad) {
                    return resolve(getters.geopointsGroups.length)
                }
                dispatch('fetchGeopointsGroups', {lite: true})
                    .then((data) => {
                        commit('updateGeopointsGroups', data)
                        commit('setGeopointsGroupsLiteLoad', true)
                        resolve(data.length)
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchGeopointsGroupsAllLite', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchGeopointsGroupsAllPages({dispatch, commit, getters}) {
            //dispatch('setLastCall', {name: 'fetchGeopointsGroupsAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchGeopointsGroupsChanged', time: Date.now() / 1000})

            return new Promise((resolve, reject) => {
                    if (!getters.apiToken) {
                        return reject(null)
                    }
                    if (!getters.geopointsGroups.length) {
                        return resolve([])
                    }
                    let pageSize = consts.querySettings.pageSize
                    let pages = Math.ceil(getters.geopointsGroups.length / pageSize)
                    let fetch = range(pages).map(i => {
                        let page = i + 1;
                        return dispatch('fetchGeopointsGroups', {page, 'page-size': pageSize})
                            .then((data) => {
                                commit('updateGeopointsGroups', data)
                            })
                            .catch(() => {
                                dispatch('fetchGeopointsGroups', {page, 'page-size': pageSize})
                            })
                    });
                    resolve(fetch)
                })
                .then((fetch) => {
                    return Promise.all(fetch)
                        .finally(() => {
                            commit('setGeopointsGroupsFullLoad', true)
                        })
                })
                .then(() => {
                    //dispatch('setLastCall', {name: 'fetchGeopointsGroupsAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchGeopointsGroupsChanged', inprogress: false})
                })
        },
        fetchGeopointsGroupsChanged({dispatch, commit, getters}, args) {
            if (!getters.apiToken || !getters.isGeopointsGroupsFullLoad) {
                return
            }
            dispatch('setLastCall', {name: 'fetchGeopointsGroupsChanged', time: Date.now() / 1000})

            args = {...consts.querySettings.filter, ...args}
            return dispatch('fetchGeopointsGroups', args)
                .then((data) => {
                    commit('updateGeopointsGroups', data)
                    return dispatch('fetchGeopointsGroups', {fields: 'id', expand: ''})
                })
                .then((data) => {
                    commit('filterGeopointsGroups', data)
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchGeopointsGroupsChanged', inprogress: false})
                });
        },
        reloadGeopointsGroupsAll({commit, dispatch}, args) {
            commit('clearGeopointsGroups')
            return dispatch('fetchGeopointsGroupsAllLite', args)
                .then(() => {
                    dispatch('fetchGeopointsGroupsAllPages', args)
                })
        },

        saveGeopointsGroup({dispatch}, geopoint) {
            let fn = (geopoint.id) ? 'updateGeopointsGroup' : 'createGeopointsGroup'
            return dispatch(fn, geopoint);
        },
        createGeopointsGroup({commit, dispatch}, geopoint) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('geopoints_groups')
                this.$api.geopointsgroups.create(geopoint, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateGeopointsGroup', response.data)
                            dispatch('fetchGeopointsGroupsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateGeopointsGroup({commit, dispatch}, geopointsGroup) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('geopoints_groups')
                this.$api.geopointsgroups.update(geopointsGroup.id, geopointsGroup, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateGeopointsGroup', response.data)
                            dispatch('fetchGeopointsGroupsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteGeopointsGroup({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('geopoints_groups')
                this.$api.geopointsgroups.delete(id, {...params})
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data.error)) {
                            commit('deleteGeopointsGroup', id)
                            dispatch('fetchGeopointsGroupsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

    },
    mutations: {
        setGeopointsGroupsLiteLoad(state, LiteLoad) {
            state.geopointsGroupsLiteLoad = state.geopointsGroupsLiteLoad || LiteLoad
        },
        setGeopointsGroupsFullLoad(state, FullLoad) {
            state.geopointsGroupsFullLoad = state.geopointsGroupsFullLoad || FullLoad
        },

        setGeopointsGroups(state, nGeopointsGroups) {
            nGeopointsGroups = nGeopointsGroups.map(u => {
                if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                return u //Object.freeze(u)
            })
            nGeopointsGroups.sort(sortByName)
            state.geopointsGroups = nGeopointsGroups
        },

        updateGeopointsGroups(state, nGeopointsGroups) {
            if (!state.geopointsGroups.length) {
                nGeopointsGroups = nGeopointsGroups.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nGeopointsGroups.sort(sortByName)
                state.geopointsGroups = nGeopointsGroups
                // const chunks = arraySplitIntoChunks(nGeopointsGroups)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.geopointsGroups.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nGeopointsGroups.forEach(function (nGeopointsGroup) {
                if (nGeopointsGroup?.name) nGeopointsGroup.name_ = nGeopointsGroup.name.toLocaleLowerCase()
                let i = state.geopointsGroups.findIndex(u => (u.id == nGeopointsGroup.id))
                if (i < 0) {
                    state.geopointsGroups.push(nGeopointsGroup) //(Object.freeze(nGeopointsGroup))
                } else
                if (!state.geopointsGroupsFullLoad || state.geopointsGroups[i][changedField] != nGeopointsGroup[changedField]) {
                    updateObjectByDiff(state.geopointsGroups[i], nGeopointsGroup)
                    // delete nGeopoint.id
                    // nGeopointsGroup = {...state.geopointsGroups[i], ...nGeopointsGroup}
                    // state.geopointsGroups[i] = nGeopointsGroup //Object.freeze(nGeopointsGroup)
                }
            })
        },
        filterGeopointsGroups(state, nGeopointsGroups) {
            // let Ids = state.geopointsGroups.map(u=> u.id)
            let nIds = nGeopointsGroups.map(u => u.id)
            let removedIds = state.geopointsGroups.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.geopointsGroups.findIndex(u => (u.id == removedId))
                if (i != -1) {
                    state.geopointsGroups.splice(i, 1)
                }
            })
        },
        updateGeopointsGroup(state, nGeopointsGroup) {
            if (nGeopointsGroup?.name) nGeopointsGroup.name_ = nGeopointsGroup.name.toLocaleLowerCase()
            let i = state.geopointsGroups.findIndex(u => (u.id == nGeopointsGroup.id))
            if (i < 0) {
                state.geopointsGroups.push(nGeopointsGroup) //(Object.freeze(nGeopointsGroup))
            } else
            if (!state.geopointsGroupsFullLoad || state.geopointsGroups[i][changedField] != nGeopointsGroup[changedField]) {
                updateObjectByDiff(state.geopointsGroups[i], nGeopointsGroup)
                // delete nGeopoint.id
                // nGeopointsGroup = {...state.geopointsGroups[i], ...nGeopointsGroup}
                // state.geopointsGroups[i] = nGeopointsGroup //Object.freeze(nGeopointsGroup)
            }
        },

        deleteGeopointsGroup(state, id) {
            let i = state.geopointsGroups.findIndex(u => (u.id == id))
            if (i != -1) {
                state.geopointsGroups.splice(i, 1)
            }
        },

        clearGeopointsGroups(state) {
            state.geopointsGroups = []
            state.geopointsGroupsLiteLoad = false
            state.geopointsGroupsFullLoad = false
        },
    },
    getters: {
        isGeopointsGroupsLiteLoad(state) {
            return state.geopointsGroupsLiteLoad
        },
        isGeopointsGroupsFullLoad(state) {
            return state.geopointsGroupsFullLoad
        },
        geopointsGroups(state) {
            return state.geopointsGroups
        },
        geopointsGroupsByIds(state) {
            return state.geopointsGroups.reduce((groupsByIds, g) => {
                groupsByIds[g.id] = g
                return groupsByIds
            }, {})
        },
        sortedGeopointsGroupsIds(state) {
            let geopointsGroups = state.geopointsGroups
            geopointsGroups.sort(sortByName)
            return geopointsGroups.map(u => u.id)
        },
    }
}
