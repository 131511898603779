const RESOURCE_NAME = '/statistics';

export default axios => ({
    statisticsCalendar(period, params) {
        return axios.get(`${RESOURCE_NAME}/calendar/${period}`, {params});
    },
    statisticsUnits(userId, period, params) {
        //if(!period) period = 'now'
        return axios.get(`${RESOURCE_NAME}/units/${userId}/${period}`, {params});
    },
    statisticsUsers(userId, period, params) {
        //if(!period) period = 'now'
        return axios.get(`${RESOURCE_NAME}/users/${userId}/${period}`, {params});
    },
    statisticsOrders(userId, period, params) {
        //if(!period) period = 'now'
        return axios.get(`${RESOURCE_NAME}/orders/${userId}/${period}`, {params});
    },
    statisticsKeysByStatus(userId, params) {
        return axios.get(`${RESOURCE_NAME}/keys/by-status/${userId}`, {params});
    },
    statisticsKeysByProducts(userId, params) {
        return axios.get(`${RESOURCE_NAME}/keys/by-products/${userId}`, {params});
    },
    statisticsKeysByUnits(userId, params) {
        return axios.get(`${RESOURCE_NAME}/keys/by-units/${userId}`, {params});
    },
});