const RESOURCE_NAME = '/user';

export default axios => ({
    fetch(params) {//expand=settings
        return axios.get(`${RESOURCE_NAME}`, {params});
    },
    update(data, params) {
        return axios.put(`${RESOURCE_NAME}`, data, {params});
    },
    request(data, params) {
        return axios.put(`${RESOURCE_NAME}/request`, data, {params});
    },
    getSettings() {
        return axios.get(`${RESOURCE_NAME}/settings`);
    },
});