// import Vue from 'vue'
// import consts from "@/consts"
// const STATUS_IN_PROGRESS = 1;
// const STATUS_READY = 2;
// const STATUS_ERROR = 3;
// const STATUS_READY_FOR_CLEAR = 4;

export default {
    state: {
        reports: [],
    },
    actions: {
        getSystemReport({commit, dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                    if (!getters.apiToken) {
                        return reject(false)
                    }
                    dispatch('setLastCall', {name: 'getSystemReport', time: Date.now() / 1000})

                    let reportId = args.id_report; delete args.id_report;
                    let timeFrom = args.time_from; delete args.time_from;
                    let timeTo = args.time_to; delete args.time_to;
                    let unitId = args.unit_id; delete args.unit_id;
                    const params = args
                    this.$api.reports.getSystemReportData(reportId, timeFrom, timeTo, unitId, {...params})
                        .then((response) => {
                            if (response.status < 400 && !response.data.error) {
                                commit('updateReport', {...params, ...response.data})//mongo_report_id: md5()

                                resolve(response.data)
                            } else {
                                reject(response)
                            }
                        })
                        .catch((error) => {
                            reject(false)
                            console.error(error);
                        })
                        .finally(() => {
                            dispatch('setLastCall', {name: 'getSystemReport', inprogress: false})
                        });
                })
        },

        execReport({commit, dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'execReport', time: Date.now() / 1000})

                const params = args
                this.$api.reports.create({...params})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error && response.data.mongo_report_id) {
                            commit('updateReport', {...params, ...response.data})
                            if (response.data.status_code == 1) {
                                setInterval(() => {
                                    dispatch('fetchReport', response.data.mongo_report_id)
                                }, 10 * 1000)
                            } else {
                                //dispatch('getReport', response.data.mongo_report_id)
                            }
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(false)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'execReport', inprogress: false})
                    });
            })
        },
        fetchReport({commit, dispatch, getters}, mongo_report_id) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchReport', time: Date.now() / 1000})

                this.$api.reports.find(mongo_report_id, {check: ''})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            if (response.data.status_code == 1) {
                                setInterval(() => {
                                    dispatch('fetchReport', mongo_report_id)
                                }, 10 * 1000)
                            } else {
                                commit('updateReport', {...response.data})
                            }
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(false)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchReport', inprogress: false})
                    });
            })
        },
        getReport({commit, dispatch, getters}, id) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'getReport', time: Date.now() / 1000})

                this.$api.reports.find(id)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateReport', {...response.data})
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(false)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'getReport', inprogress: false})
                    });
            })
        },
        exportReport({commit, dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'exportReport', time: Date.now() / 1000})

                this.$api.reports.export(args.id, args.format)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateReport', {...args, ...response.data})
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(false)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'exportReport', inprogress: false})
                    });
            })
        },
    },
    mutations: {
        updateReport(state, report) {
            let i = state.reports.findIndex(r => (r.mongo_report_id == report.mongo_report_id))
            if (i < 0) {
                state.reports.push(report) //(Object.freeze(report))
            } else {
                report = {...state.reports[i], ...report}
                state.reports[i] = report //Object.freeze(report)
            }
        },
    },
    getters: {
        getReports(state) {
            return state.reports
        },
        // getReportsById(state) {
        //     return state.reports
        //         .reduce((reportById, report) => {
        //             reportById[report.id] = report
        //             return reportById
        //         }, {})
        // }
    }
}