<template>
    <section class="chart chart-donut">
        <div class="chart__header">
            <div class="chart__title">
                {{ title }}
            </div>
            <div class="chart__commands">
                <FieldGroup_k_dropdownlist
                    class="chart__commands__item"
                    :select-wrap="'select-wrap-web'"
                    :field-group_class="'field-group_view_compact chart-select field-group__icon-50 mr__12'"
                    :icon="'input__select-3'"
                    :filterable="true"
                    :defaultItem="false"
                    :items="users"
                    v-model="userId"
                    v-if="userId"
                />
                <template v-if="buttonTypes.length">
                    <button v-for="(button, i) in buttonTypes"
                            :key="i"
                            class="button button_view_icon button-chart chart__commands__item"
                            :class="[ (button.id == type) && 'button-chart--active']"
                            @click="$emit('update:type', button.id)"
                    >
                    <span class="icon icon_size_32">
                        <span class="icon__symbol">
                            <SvgIcon class="icon" :name="button.icon"/>
                        </span>
                    </span>
                    </button>
                </template>
                <FieldGroup_k_dropdownlist
                    class="chart__commands__item select-wrap-web"
                    :select-wrap="'select-wrap-web'"
                    :field-group_class="'field-group_view_compact chart-select field-group__icon-50 mr__12'"
                    :icon="'input__select-3'"
                    :filterable="false"
                    :defaultItem="false"
                    :items="dataSelectTotal"
                    v-model="dataTotal"
                    v-if="dataSelectTotal.length"
                />
                <FieldGroup_k_dropdownlist
                    class="chart__commands__item select-wrap-web"
                    :select-wrap="'select-wrap-web'"
                    :field-group_class="'field-group_view_compact chart-select field-group__icon-50 mr__12'"
                    :icon="'input__select-3'"
                    :filterable="false"
                    :defaultItem="false"
                    :sortBy="'sort'"
                    :items="dataSelectPeriod"
                    v-model="dataPeriod"
                    v-if="dataSelectPeriod.length"
                />
                <FieldGroup_k_dropdownlist
                    class="chart__commands__item"
                    :select-wrap="'select-wrap-web'"
                    :field-group_class="'field-group_view_compact chart-select field-group__icon-50 mr__12'"
                    :icon="'input__select-3'"
                    :filterable="false"
                    :defaultItem="false"
                    :sortBy="'sort'"
                    :items="dataSelectDivisionScale"
                    v-model="dataDivisionScale"
                    v-if="dataSelectDivisionScale.length"
                />
            </div>
        </div>
        <div class="chart__header-export">
            <div class="chart__title chart__header-export__item">
                {{ title }} <span class="chart__subtitle" v-if="periodExport && scaleExport">for {{ periodExport }} by {{ scaleExport }}</span>
            </div>
        </div>
        <div class="chart__body" :style="{display}">
            <slot/>
        </div>
    </section>
</template>

<script>

import {mapGetters} from "vuex";
export default {
    name: "ChartWrap",
    emits: ['update:period', 'update:total', 'update:type', 'update:divisionScale', 'update:user'],
    props: {
        'title': String,
        'dataSelectPeriod': {
            type: [Array],
            default: () => {
                return []
            }
        },
        'dataSelectDivisionScale': {
            type: Array,
            default: () => {
                return []
            }
        },
        'dataSelectTotal': {
            type: [Array],
            default: () => {
                return []
            }
        },
        'buttonTypes': {
            type: [Array],
            default: () => {
                return []
            }
        },
        'period': String,
        'total': String,
        'type': String,
        'user': [String, Number],
        'divisionScale': String,
        'display': String,
    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters([
            "getUsers"
        ]),
        periodExport(){
            return this.dataSelectPeriod.find(p => p.id == this.dataPeriod)?.name?.toLowerCase()
        },
        scaleExport(){
            return this.dataSelectDivisionScale.find(s => s.id == this.dataDivisionScale)?.name?.toLowerCase()
        },
        users() {
            return this.getUsers
                .map(u => {
                    return {id: u.id, name: u.login}
                })
        },
        userId: {
            get() {
                return this.user
            },
            set(val) {
                this.$emit('update:user', val)
            }
        },
        dataPeriod: {
            get() {
                return this.period
            },
            set(value) {
                this.$emit('update:period', value)
            }
        },
        dataTotal: {
            get() {
                return this.total
            },
            set(value) {
                this.$emit('update:total', value)
            }
        },
        dataDivisionScale: {
            get() {
                return this.divisionScale
            },
            set(value) {
                this.$emit('update:divisionScale', value)
            }
        }
    },
}
</script>

<style lang="scss">
.chart{
    padding: 30px;
    background: white;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    &__header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
    }
    &__header-export{
        display: none;
        align-items: center;
        &__item{
            display: flex;
            //justify-content: center;
            align-items: flex-end;
            margin: 0 5px;
        }
        .chart__title{
            @include font(16px, 22px, 700, none, normal, #415C84);
        }
        .chart__subtitle{
            margin: 0 5px;
            color: #415C84;
            @include font(13px, 19px, 700, none, normal, #415C84);
        }
    }
    &__body{
        height: 100%;
    }
    &__title{
        color: #415C84;
        @include font(24px, 29px, 700, none, normal, #415C84);
    }
    &__commands{
        display: flex;
        align-items: center;
        &__item{
            margin: 0 5px;
            &:last-child{
                margin-right: 0;
            }
        }
    }
}
.chart-donut{

    &__center{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &__title{
        }
        &__subtitle{
            text-align: center;
        }
    }
}
@media all and (max-width: 950px) {
    .chart{
        &__header{
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
        &__commands{
            flex-wrap: wrap;
            .field-group{
                max-width: 180px;
            }
            & > div , & > button{
                margin-bottom: 5px;
            }
        }
    }
}
</style>