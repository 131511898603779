// import Vue from 'vue'
import consts from "@/consts"
import {diffObjects, getApiProps} from "@/lib/lib";
import range from "lodash/range";
// import {updateObjectByDiff} from "@/lib/lib";
const changedField = 'time';

export default {
    state: {
        unitsLmsgsLiteLoad: false,
        unitsLmsgsFullLoad: false,
        unitsLmsgs: [],
        unitsLmsgsTimeAgo: [],
    },
    actions: {
        fetchUnitsLmsgs/*all*/({dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchUnitsLmsgs', time: Date.now() / 1000})

                const params = getApiProps('unitslmsgs', args)
                let data = {...args}
                if (args?.lite) params.withAddress = data.withAddress = 0;
                if (!args?.force) data.lmsgtimes = getters.unitsLmsgsTimeByIds
                this.$api.units.getLmsgs(data, {...params})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchUnitsLmsgs', inprogress: false})
                    });
            })
        },
        fetchUnitsLmsgsAll({dispatch, commit}) {
            return new Promise((resolve, reject) => {
                this.$api.init.getUnitsLmsgs()
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {

                            commit('setUnitsLmsgs', response.data)
                            commit('setUnitsLmsgsLiteLoad', true)

                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchUnitsLmsgsAll', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchUnitsLmsgsAllLite({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (getters.isUnitsLmsgsLiteLoad) {
                    return resolve(getters.units.length)//!!!
                }
                dispatch('fetchUnitsLmsgs', {lite: true, force: true, ...args})
                    .then((data) => {
                        commit('updateUnitsLmsgs', data)
                        commit('setUnitsLmsgsLiteLoad', true)
                        resolve(data.length)
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchUnitsLmsgsAllLite', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchUnitsLmsgsAllPages({dispatch, commit, getters}, args) {
            //dispatch('setLastCall', {name: 'fetchUnitsLmsgsAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchUnitsLmsgsChanged', time: Date.now() / 1000})

            return new Promise((resolve, reject) => {
                    if (!getters.apiToken) {
                        return reject(null)
                    }
                    if (!getters.units.length) {
                        return resolve([])
                    }

                    let pageSize = consts.querySettings.pageSize
                    let pages = Math.ceil(getters.units.length / pageSize)
                    let fetch = range(pages).map(i => {
                        let page = i + 1;
                        return dispatch('fetchUnitsLmsgs', {page, 'page-size': pageSize, force: true, ...args})
                            .then((data) => {
                                commit('updateUnitsLmsgs', data)
                            })
                            .catch(() => {
                                dispatch('fetchUnitsLmsgs', {page, 'page-size': pageSize, force: true, ...args})
                            })
                    });
                    resolve(fetch)
                })
                .then((fetch) => {
                    return Promise.all(fetch)
                        .finally(() => {
                            commit('setUnitsLmsgsFullLoad', true)
                        })
                })
                .then(() => {
                    //dispatch('setLastCall', {name: 'fetchUnitsLmsgsAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchUnitsLmsgsChanged', inprogress: false})
                })
        },
        fetchUnitsLmsgsChanged({dispatch, commit, getters}, args) {
            if (!getters.apiToken || !getters.isUnitsLmsgsFullLoad) {
                return
            }
            dispatch('setLastCall', {name: 'fetchUnitsLmsgsChanged', time: Date.now() / 1000})

            args = {...consts.querySettings.filter, ...args}
            return dispatch('fetchUnitsLmsgs', args)
                .then((data) => {
                    commit('updateUnitsLmsgs', data)
                    // return dispatch('fetchUnitsLmsgs', {fields: 'object_id',expand: '', force: true})
                })
                // .then((data) => {
                //     commit('filterUnitsLmsgs', data)
                // })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchUnitsLmsgsChanged', inprogress: false})
                });
        },
        reloadUnitsLmsgsAll({commit, dispatch}, args) {
            commit('clearUnitsLmsgsSpeed')
            return dispatch('fetchUnitsLmsgsAllLite', args)
                .then(() => {
                    dispatch('fetchUnitsLmsgsAllPages', args)
                })
        },

        fetchUnitLmsg({commit, dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken || !args.id) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchUnitLmsg', time: Date.now() / 1000})

                const params = {lmsgtime: getters.unitsLmsgsTimeByIds[args.id], withAddress: 1, ...args}
                this.$api.units.getLmsg(args.id, params)
                    .then((response) => {
                        if (response.status < 400 && response.data) {
                            commit('updateUnitsLmsg', response.data)
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchUnitLmsg', inprogress: false})
                    });
            })
        },

        updateUnitCounters({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchUnitsLmsgs', time: Date.now() / 1000})

                const params = getApiProps('units')
                this.$api.units.updateCounters(args.id, args.counters, {...params})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            // console.log(response.data)
                            commit('updateUnitsLmsg', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        incrementUnitsLmsgTimeAgo({commit}) {
            commit('incrementUnitsLmsgTimeAgo')
        },

        //sayHello() {}
    },
    mutations: {
        setUnitsLmsgsLiteLoad(state, LiteLoad) {
            state.unitsLmsgsLiteLoad = state.unitsLmsgsLiteLoad || LiteLoad
        },
        setUnitsLmsgsFullLoad(state, FullLoad) {
            state.unitsLmsgsFullLoad = state.unitsLmsgsFullLoad || FullLoad
        },

        setUnitsLmsgs(state, nLmsgs) {
            state.unitsLmsgs = nLmsgs.map(l => Object.freeze(l))
            //Lmsgs TimeAgo
            state.unitsLmsgsTimeAgo = nLmsgs.map(nLmsg => {
                return {object_id: nLmsg.object_id, time: nLmsg.time, timeAgo: nLmsg.timeAgo, isActive: nLmsg.timeAgo < consts.status.offline}
            })
        },

        updateUnitsLmsgs(state, nLmsgs) {
            if (!state.unitsLmsgs.length) {
                state.unitsLmsgs = nLmsgs.map(l => Object.freeze(l))
                //Lmsgs TimeAgo
                state.unitsLmsgsTimeAgo = nLmsgs.map(nLmsg => {
                    return {object_id: nLmsg.object_id, time: nLmsg.time, timeAgo: nLmsg.timeAgo, isActive: nLmsg.timeAgo < consts.status.offline}
                })
                return true
            }
            //console.log('updateLmsgs', state.unitsLmsgs.length, nLmsgs.length)
            nLmsgs.forEach(function (nLmsg) {
                let i = state.unitsLmsgs.findIndex(lmsg => (lmsg.object_id == nLmsg.object_id))
                if (i < 0) {
                    state.unitsLmsgs.push(Object.freeze(nLmsg))
                } else
                if (!state.unitsLmsgsFullLoad || state.unitsLmsgs[i][changedField] != nLmsg[changedField]) {
                    // updateObjectByDiff(state.unitsLmsgs[i], nLmsg)
                    // delete nLmsg.object_id
                    let newLmsg = diffObjects(state.unitsLmsgs[i], nLmsg) || {}
                    state.unitsLmsgs[i] = Object.freeze({...state.unitsLmsgs[i], ...newLmsg})
                }
                //Lmsgs TimeAgo
                nLmsg = {object_id: nLmsg.object_id, time: nLmsg.time, timeAgo: nLmsg.timeAgo, isActive: nLmsg.timeAgo < consts.status.offline}
                let ii = state.unitsLmsgsTimeAgo.findIndex(lmsg => (lmsg.object_id == nLmsg.object_id))
                if (ii < 0) {
                    state.unitsLmsgsTimeAgo.push(nLmsg)
                } else {
                    // updateObjectByDiff(state.unitsLmsgsTimeAgo[ii], nLmsg)
                    // delete nLmsg.object_id
                    nLmsg = diffObjects(state.unitsLmsgsTimeAgo[ii], nLmsg) || {}
                    state.unitsLmsgsTimeAgo[ii] = {...state.unitsLmsgsTimeAgo[ii], ...nLmsg}
                }
            })
        },
        filterUnitsLmsgs(state, nLmsgs) {
            // let Ids = state.unitsLmsgs.map(l => l.object_id)
            let nIds = nLmsgs.map(l => l.object_id)
            let removedIds = state.unitsLmsgs.filter(l => !nIds.includes(l.object_id)).map(l => l.object_id)
            removedIds.forEach(removedId => {
                let i = state.unitsLmsgs.findIndex(l => (l.object_id == removedId))
                if (i != -1) {
                    state.unitsLmsgs.splice(i, 1)
                }
            })
        },
        updateUnitsLmsg(state, nLmsg) {
            let i = state.unitsLmsgs.findIndex(lmsg => (lmsg.object_id == nLmsg.object_id))
            if (i < 0) {
                state.unitsLmsgs.push(Object.freeze(nLmsg))
            } else
            if (!state.unitsLmsgsFullLoad || state.unitsLmsgs[i][changedField] != nLmsg[changedField]) {
                // updateObjectByDiff(state.unitsLmsgs[i], nLmsg)
                // delete nLmsg.object_id
                let newLmsg = diffObjects(state.unitsLmsgs[i], nLmsg) || {}
                state.unitsLmsgs[i] = Object.freeze({...state.unitsLmsgs[i], ...newLmsg})
            }
            //Lmsgs TimeAgo
            nLmsg = {object_id: nLmsg.object_id, time: nLmsg.time, timeAgo: nLmsg.timeAgo}
            let ii = state.unitsLmsgsTimeAgo.findIndex(lmsg => (lmsg.object_id == nLmsg.object_id))
            if (ii < 0) {
                state.unitsLmsgsTimeAgo.push(nLmsg)
            } else {
                // updateObjectByDiff(state.unitsLmsgsTimeAgo[ii], nLmsg)
                // delete nLmsg.object_id
                nLmsg = diffObjects(state.unitsLmsgsTimeAgo[ii], nLmsg) || {}
                state.unitsLmsgsTimeAgo[ii] = {...state.unitsLmsgsTimeAgo[ii], ...nLmsg}
            }
        },

        updateUnitLmsgCounters(state, nLmsg) {
            let i = state.unitsLmsgs.findIndex(lmsg => (lmsg.object_id == nLmsg.object_id))
            if (i < 0) {
                state.unitsLmsgs.push(Object.freeze(nLmsg))
            } else
            if (state.unitsLmsgs[i][changedField] != nLmsg[changedField]) {
                // updateObjectByDiff(state.unitsLmsgs[i], nLmsg)
                // delete nLmsg.object_id
                let newLmsg = diffObjects(state.unitsLmsgs[i], nLmsg) || {}
                state.unitsLmsgs[i] = Object.freeze({...state.unitsLmsgs[i], ...newLmsg})
            }
            //Lmsgs TimeAgo
            nLmsg = {object_id: nLmsg.object_id, time: nLmsg.time, timeAgo: nLmsg.timeAgo, isActive: nLmsg.timeAgo < consts.status.offline}
            let ii = state.unitsLmsgsTimeAgo.findIndex(lmsg => (lmsg.object_id == nLmsg.object_id))
            if (ii < 0) {
                state.unitsLmsgsTimeAgo.push(nLmsg)
            } else {
                // updateObjectByDiff(state.unitsLmsgsTimeAgo[ii], nLmsg)
                // delete nLmsg.object_id
                nLmsg = diffObjects(state.unitsLmsgsTimeAgo[ii], nLmsg) || {}
                state.unitsLmsgsTimeAgo[ii] = {...state.unitsLmsgsTimeAgo[ii], ...nLmsg}
            }
        },

        clearUnitsLmsgsSpeed(state) {
            // state.unitsLmsgs.forEach(lmsg => {
            //     ['time','speed','timeAgo','isActive'].forEach(fld => {
            //         lmsg[fld] = null
            //     })
            //     //lmsg.time = 0;lmsg.speed = null;lmsg.timeAgo = null;lmsg.isActive = null;
            // })
            state.unitsLmsgs = state.unitsLmsgs.map(lmsg => {
                return Object.freeze({...lmsg, time: 0, speed: null, timeAgo: null, isActive: null})
            })
        },
        clearUnitsLmsgs(state) {
            state.unitsLmsgs = []
            state.unitsLmsgsTimeAgo = []
            state.unitsLmsgsLiteLoad = false
            state.unitsLmsgsFullLoad = false
        },

        incrementUnitsLmsgTimeAgo(state) {
            state.unitsLmsgsTimeAgo.forEach(lmsg => {
                if (typeof lmsg.timeAgo === 'number') {
                    lmsg.timeAgo++
                    lmsg.isActive = (lmsg.timeAgo < consts.status.offline)
                }
            })
        },
    },
    getters: {
        isUnitsLmsgsLiteLoad(state) {
            return state.unitsLmsgsLiteLoad
        },
        isUnitsLmsgsFullLoad(state) {
            return state.unitsLmsgsFullLoad
        },
        unitsLmsgs(state) {
            return state.unitsLmsgs
        },
        unitsIdsIsMoving(state) {
            return state.unitsLmsgs
                .filter(lmsg => lmsg?.status?.isMoving === true)
                .map(lmsg => lmsg.object_id)
        },
        unitsIdsIsParked(state) {
            return state.unitsLmsgs
                .filter(lmsg => lmsg?.status?.isParked === true)
                .map(lmsg => lmsg.object_id)
        },
        unitsIdsIsIgnitionOn(state) {
            return state.unitsLmsgs
                .filter(lmsg => !!lmsg?.status?.ignition?.value)
                .map(lmsg => lmsg.object_id)
        },
        unitsLmsgsTimeAgo(state) {
            return state.unitsLmsgsTimeAgo
        },
        unitsIdsIsActive(state) {
            return state.unitsLmsgsTimeAgo
                .filter(lmsg => lmsg.isActive === true)
                .map(lmsg => lmsg.object_id)
        },
        unitsIdsIsNotActive(state) {
            return state.unitsLmsgsTimeAgo
                .filter(lmsg => lmsg.isActive === false)
                .map(lmsg => lmsg.object_id)
        },
        unitsLmsgsIds(state) {
            return state.unitsLmsgs.map(lmsg => lmsg.object_id)
        },
        unitsLmsgsTimeByIds(state) {
            return state.unitsLmsgs.reduce((timeByLmsgIds, lmsg) => {
                if (lmsg.time)
                    timeByLmsgIds[lmsg.object_id] = lmsg.time
                return timeByLmsgIds
            }, {})
        },
        unitsLmsgsLatlngByIds(state) {
            return state.unitsLmsgs.reduce((latlngBylmsgIds, lmsg) => {
                if (lmsg.latlng) latlngBylmsgIds[lmsg.object_id] = lmsg.latlng
                return latlngBylmsgIds
            }, {})
        },
        unitsLmsgsAddressByIds(state) {
            return state.unitsLmsgs.reduce((addressBylmsgIds, lmsg) => {
                let address = lmsg.address
                if (!lmsg?.address && lmsg?.latlng) {
                    address = `lat: ${lmsg.latlng.lat}, lon: ${lmsg.latlng.lng}`
                    //lmsg = {...lmsg, address}
                }
                addressBylmsgIds[lmsg.object_id] = address
                return addressBylmsgIds
            }, {})
        },
        unitsLmsgsTimeAgoByIds(state) {
            return state.unitsLmsgsTimeAgo.reduce((timeAgoByLmsgIds, lmsg) => {
                if (lmsg.time) timeAgoByLmsgIds[lmsg.object_id] = lmsg.timeAgo
                return timeAgoByLmsgIds
            }, {})
        },
        unitsLmsgsIsActiveByIds(state) {
            return state.unitsLmsgsTimeAgo.reduce((lmsgIsActiveByIds, lmsg) => {
                if (lmsg.time) lmsgIsActiveByIds[lmsg.object_id] = lmsg.isActive
                return lmsgIsActiveByIds
            }, {})
        },
        unitsLmsgsByIds(state) {
            return state.unitsLmsgs.reduce((lmsgsByIds, lmsg) => {
                if (!lmsg?.address && lmsg?.latlng) {
                    let address = `lat: ${lmsg.latlng.lat}, lon: ${lmsg.latlng.lng}`
                    lmsg = {...lmsg, address}
                }
                lmsgsByIds[lmsg.object_id] = lmsg
                return lmsgsByIds
            }, {})
        },
        unitsSensorsById(state) {
            return state.unitsLmsgs.reduce((sensorsByIds, lmsg) => {
                sensorsByIds[lmsg.object_id] = lmsg.sensors
                return sensorsByIds
            }, {})
        },
        unitsSensorsListById(state) {
            return state.unitsLmsgs.reduce((sensorsByIds, lmsg) => {
                sensorsByIds[lmsg.object_id] = Object.keys(lmsg?.sensors || {})
                    .map(sensor => {
                        return {name: sensor, ...lmsg?.sensors[sensor]}
                    })
                return sensorsByIds
            }, {})
        },
    }
}
