const config = {
    "api": {
        // "baseURL": "https://apitest.prometheuspro.us/api",
    },
    "exports": {
        // "baseURL": "https://apitest.prometheuspro.us/tmp/",
    },
    "customize": {
        // "baseURL": "https://apitest.prometheuspro.us/api/pm",
    },
    "privatelabel": {
        // "baseURL": "https://apitest.prometheuspro.us",
    },
}

export default config