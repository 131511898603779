<template>
    <ul class="tabs-control">
        <template v-for="item in list" :key="item.name">
            <li class="tabs-control__item"
                :style="{opacity: (item.route_name && item.setRoute) ? 1 : 0.5}"
                :class="[mainRouteName == item.route_name && 'tabs-control-item--active', item.icon && 'tabs-control__with-icon']"
                @click="setRoute(item)"
            >
                <div v-if="item.icon">
                    <SvgIcon :name="item.icon" class="icon"/>
                </div>
                <div>
                    {{ item.name }}
                </div>
            </li>
        </template>
    </ul>
</template>

<script>
import SvgIcon from "@/components/_base/SvgIcon";
import {getMainParentRouteName} from "@/lib/lib";

export default {
    setup() {
        return {
            getMainParentRouteName
        }
    },
    name: "TabsControlByRoute",
    components: {SvgIcon},
    props: {
        unitId: [String, Number],
        list: {
            type: [Array, Object],
            default: () => []
        }
    },
    computed: {
        routeName() {
            return this.$route.name
        },
        routeMeta() {
            return this.$route.meta
        },
        mainRouteName() {
            return this.routeMeta.mainRouteName
        }
    },
    methods: {
        setRoute(item) {
            if(!item.setRoute) return false;
            item.setRoute()
        }
    }
}
</script>

<style lang="scss" scoped>
.tabs-control{
    display: flex;
    align-items: center;

    &__item{
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 10px;
        border-bottom: solid 1px var(--border-color);
        @include font(12px, normal, 500, none, normal, var(--color-default));
        cursor: pointer;
        &:not(:first-child):after{
            content: '';
            display: block;
            width: 1px;
            height: 50%;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background: var(--border-color);
        }

        &.tabs-control-item--active {
            font-weight: 700;
            color: var(--color-default);
            &:before{
                content: '';
                display: block;
                width: 100%;
                height: 3px;
                background: var(--border-color--active);
                position: absolute;
                left: 0;
                bottom: -1px;
            }
        }
    }

    &__with-icon {
        flex-direction: column;
        gap: 5px;
        white-space: nowrap;
        border: none;
        &:not(:first-child):after {
            content: none;
        }
        &.tabs-control-item--active {
            position: relative;
            color: var(--tabs-active-color-with-icon);
            z-index: 2;
            &:before {
                width: 100%;
                height: 100%;
                opacity: var(--tabs-activebg-opasity-with-icon);
                background: var(--tabs-activebg-with-icon);
                box-shadow: var(--tabs-active-box-shadow-with-icon);
                border-radius: 8px;
                z-index: -1;
            }
        }
    }
}
</style>