<template>
    <UnitInfo_indicator_movement :unitId="unitId"/>
</template>
<script>

    import UnitInfo_indicator_movement from "@/components/units/info/UnitInfo_indicator_movement";
    export default {
        name: 'UnitsList_unit_moving_status',
        components: {UnitInfo_indicator_movement},
        props: {
            unitId: [String, Number],
        },
    }
</script>