import store from '@/store';
// import moment from "moment";
import consts from "@/consts";
const routes = (process.env.VUE_APP_PACKAGE !== 'prometheus.portal') ? [] : [
    {
        path: '/notificationsevents/:unitId(\\d+)?/:date?/:notificationType?/:notificationId?',
        name: consts.routerNames.notificationsevents.main,
        meta: {
            list: 'NotificationsEventsList',
            mainRouteName: consts.routerNames.notificationsevents.main,
            showMapInfo: true,
            showMap: true,
            setRoute: {
                notifications: (route, router, unitId, date) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.notificationsevents.main) {
                        if(this.$isDevelopment) console.warn('notifications', route);
                        return false
                    }
                    date = date || store.getters.getDateToday
                    router.push({
                        name: consts.routerNames.notificationsevents.main,
                        params: {
                            unitId,
                            date,
                        },
                        query: {}
                    })
                },
                notificationsEdit: (route, router) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.notificationsevents.main) {
                        if(this.$isDevelopment) console.warn('notificationsEdit', route);
                        return false
                    }
                    let unitId = route?.params?.unitId
                    let date = route?.params?.date
                    let notificationType = route?.params?.notificationType
                    let notificationId = route?.params?.notificationId
                    router.push({
                        name: consts.routerNames.notificationsevents.edit.main,
                        params: {
                            unitId,
                            date,
                            notificationType,
                            notificationId,
                        },
                        query: {}
                    })
                },
                notificationsCloseEdit: (route, router) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.notificationsevents.main) {
                        if(this.$isDevelopment) console.warn('notificationsCloseEdit', route);
                        return false
                    }
                    let unitId = route?.params?.unitId
                    let date = route?.params?.date
                    let notificationType = route?.params?.notificationType
                    let notificationId = route?.params?.notificationId
                    router.push({
                        name: consts.routerNames.notificationsevents.main,
                        params: {
                            unitId,
                            date,
                            notificationType,
                            notificationId,
                        },
                        query: {}
                    })
                },
                notificationsUnitId: (route, router, unitId) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.notificationsevents.main) {
                        if(this.$isDevelopment) console.warn('notificationsUnitId', route);
                        return false
                    }
                    let date = route?.params?.date
                    router.push({
                        name: consts.routerNames.notificationsevents.main,
                        params: {
                            unitId,
                            date
                        },
                        query: {...route.query}
                    })
                },
                notificationsDate: (route, router, date) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.notificationsevents.main) {
                        if(this.$isDevelopment) console.warn('notificationsDate', route);
                        return false
                    }
                    let unitId = route?.params?.unitId
                    router.push({
                        name: consts.routerNames.notificationsevents.main,
                        params: {
                            unitId,
                            date,
                        },
                        query: {...route.query}
                    })
                },
                notificationsId: (route, router, notificationType, notificationId) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.notificationsevents.main) {
                        if(this.$isDevelopment) console.warn('notificationsId', route);
                        return false
                    }
                    let unitId = route?.params?.unitId
                    let date = route?.params?.date
                    router.push({
                        name: consts.routerNames.notificationsevents.main,
                        params: {
                            unitId,
                            date,
                            notificationType,
                            notificationId,
                        },
                        query: {...route.query}
                    })
                },
                notificationsPopup: (route, router, notificationType, notificationId) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.notificationsevents.main) {
                        if(this.$isDevelopment) console.warn('notificationsPopup', route);
                        return false
                    }
                    let unitId = route?.params?.unitId
                    let date = route?.params?.date
                    router.push({
                        name: consts.routerNames.notificationsevents.popup.main,
                        params: {
                            unitId,
                            date,
                            notificationType,
                            notificationId,
                        },
                        query: {...route.query}
                    })
                },
                notificationsFilter: (route, router, filter) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.notificationsevents.main) {
                        if(this.$isDevelopment) console.warn('notificationsFilter', route);
                        return false
                    }
                    let unitId = route?.params?.unitId
                    let date = route?.params?.date
                    let notificationType = route?.params?.notificationType
                    let notificationId = route?.params?.notificationId
                    router.push({
                        name: consts.routerNames.notificationsevents.main,
                        params: {
                            unitId,
                            date,
                            notificationType,
                            notificationId,
                        },
                        query: {
                            name: filter?.name || undefined,
                            type: filter?.type || undefined,
                        }
                    })
                },
                notificationsEvent: (route, router, notificationEventType, notificationEventXid) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.notificationsevents.main) {
                        if(this.$isDevelopment) console.warn('notificationsEvent', route);
                        return false
                    }
                    let unitId = route?.params?.unitId
                    let date = route?.params?.date
                    let notificationType = route?.params?.notificationType
                    let notificationId = route?.params?.notificationId
                    router.push({
                        name: consts.routerNames.notificationsevents.main,
                        params: {
                            unitId,
                            date,
                            notificationType,
                            notificationId,
                            notificationEventType,
                            notificationEventXid,
                        },
                        query: {...route.query}
                    })
                },
                returnToParent:(route, router) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.notificationsevents.main) {
                        if(this.$isDevelopment) console.warn(consts.routerNames.notificationsevents.main+' returnToParent', route);
                        return false;
                    }
                    let name = route.meta?.parentRouteName || consts.routerNames.notificationsevents.main;
                    router.push({
                        name: name,
                        params: {
                            ...route.params,
                        },
                        query: {...route.query}
                    })
                },
            }
        },
        component: () => import('@/views/prometheus/portal/NotificationsEventsPage.vue'),
        children: [
            {
                path: 'edit',
                name: consts.routerNames.notificationsevents.edit.main,
                meta: {
                    mainRouteName: consts.routerNames.notificationsevents.main,
                    parentRouteName: consts.routerNames.notificationsevents.main,
                    sectionInfoByRoute: true
                },
                props: {
                    sectionInfoByRoute: () => ({
                        time: Date.now()
                    })
                },
                components: {
                    sectionInfoByRoute: () => import('@/components/notifications_events_history/sectionInfo/NotificationsProps.vue')
                }
            },
            {
                path: 'popup',
                name: consts.routerNames.notificationsevents.popup.main,
                meta: {
                    mainRouteName: consts.routerNames.notificationsevents.main,
                    parentRouteName: consts.routerNames.notificationsevents.main,
                    sectionPopupByRoute: true
                },
                props: {
                    sectionPopupByRoute: function (route) {
                        return {
                            eventXid: route.params.notificationId
                        }
                    }
                },
                components: {
                    sectionPopupByRoute: () => import('@/components/video_events/sectionInfo/VideoEventView.vue')
                }
            }
        ]
        // beforeEnter: (to) => {
        //     if(!to.params.date){
        //         return {...to, params: {...to.params, date: store.getters.getDateToday}}
        //     }
        // },
    },
];

export default routes;