<template>
    <UnitInfo__sensors_template/>
</template>

<script>
import UnitInfo__sensors_template from "@/components/units/sensors/UnitInfo__sensors_template.vue"
export default {
    props:{
        unitId: [String, Number],
    },
    components:{
        UnitInfo__sensors_template,
    },
    name: "UnitInfo__sensors_empty",
    data() {
        return {
        }
    },
    computed:{
    },
    mounted() {
    }
}
</script>

<style scoped>

</style>