<template>
    <UnitInfo_info :unitId="unitId"/>
    <router-view :class="$attrs.class"/>
</template>

<script>
import UnitInfo_info from '@/components/units/pageInfo/UnitInfo_content.prometheus.vue';
import {mapGetters, mapMutations} from "vuex";
export default {
    name: "UnitInfo",
    props: {
        unitId: [String, Number],
    },
    data() {
        return {
            showInfo: true,
        }
    },
    components: {
        UnitInfo_info,
    },
    computed: {
        ...mapGetters([
        ]),
        routeName() {
            return this.$route.name
        },
    },
    methods: {
        ...mapMutations([
        ]),
        onCloseSectionInfoByRoute(){
            this.setRoute.returnToParent(this.$route, this.$router)
        },
    },
    watch: {
        routeName: {
            handler: function (nVal, OldVal){
                if(nVal != OldVal){
                    this.showInfo = true
                }
            },
            deep: true,
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
<style>
.show-info--close #sectionInfoByPage {
    display: none;
}
</style>