<template>
    <div class="checkbox-control"
         :class="controlClass"
         @click.prevent.stop="setValue"
    >
        <label class="checkbox-control__label">
            <span class="checkbox-control__text">{{ label }}</span>
            <input class="checkbox-control__checkbox" type="checkbox" v-model="checkbox" :disabled="disabled">
            <span class="checkbox-control__switch"></span>
        </label>
    </div>
</template>

<script>
export default {
    name: "CheckboxControl",
    emits:['update:modelValue'],
    props: {
        'controlClass': {
            type: [String, Array, Object],
            default: () => ''
        },
        'label': String,
        'modelValue': [Number, Boolean],
        'disabled' : [Boolean],
        'isRadio': {
            type: Boolean,
            default: () => false
        },
    },
    data() {
        return {}
    },
    computed: {
        checkbox: {
            get(){ return !!this.modelValue },
            set(v){
                if(this.isRadio && !v) return false;
                this.$emit('update:modelValue', v)
            }
        }
    },
    methods:{
        setValue(){
            if(!this.disabled) this.checkbox = !this.checkbox
        }
    }
}
</script>

<style lang="scss" scoped>
.checkbox-control {
    display: flex;
    align-items: center;
    //height: 2.25rem;
    min-height: 2.25rem;
    background: var(--color-white);
    border-radius: var(--border-radius-lg);
    transition: all var(--transition-duration-xl) ease-in-out;

    &:hover {
        background: rgba(var(--color-white-rgb), .75);
    }

    &__label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        padding: .5rem 1rem;
        cursor: pointer;
        position: relative;
        user-select: none;
        @include font(14px, 14px, 700, none, normal, var(--color-font-light));

        & > input[type="checkbox"] {
            //display:none;
            position: absolute;
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0;
            display: flex;
            font-size: 0;
            top: 0;
            left: 0;
            border-radius: var(--border-radius-lg);
            z-index: -1;
            appearance: none;
            outline: none;

            &:focus {
                box-shadow: 0 0 0 2px var(--color-primary-500);
            }

            &[disabled] + .checkbox-control__switch {
                cursor: not-allowed;
                background-color: var(--color-neutral-50);
            }

            &:checked + .checkbox-control__switch {
                background: var(--color-secondary-600);

                &:before {
                    left:50%;
                }
            }

            &:checked[disabled] + .checkbox-control__switch {
                background: var(--color-secondary-200);
            }
        }
    }

    &__switch {
        height: 1.25rem;
        left: 0;
        background-color: var(--color-neutral-100);
        border-radius: .625rem;
        display: inline-block;
        position: relative;
        top: 0;
        transition: all var(--transition-duration-xl) ease-in-out;
        width: 2.25rem;
        min-width: 2.25rem;
        cursor: pointer;

        &:before {
            display: block;
            content:'';
            height: 1rem;
            position: absolute;
            width: 1rem;
            background-color: var(--checbox-color);
            border-radius: 50%;
            left: 0.125rem;
            top: 0.125rem;
            transition: all var(--transition-duration-xl) ease-in-out;
            //box-shadow: 0 1px 0 0 rgba(0, 21, 51, 0.2);
            box-shadow: 0 1px 0 0 rgba(var(--color-neutral-700-rgb), .125);
        }
    }

    &__text {
        margin-right: .5rem;
    }
}
</style>