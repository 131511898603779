<template>
    <div class="filter-template">
        <div class="horizontal-line">
            <div class="no-wrap-items">
                <FieldGroup_input
                    :field-group_class="'field-group_view_calendar field-group_view_table field-group__icon-50 field-group_view_compact'"

                    :id="'login'"
                    :label="''"
                    :error="[]"
                    v-model="value"
                    @update:modelValue="(v) => {
                             object.onChange({
                                operator: 'contains',
                                field: object.field,
                                value: v || undefined,
                             });
                        }"
                />
            </div>
        </div>
    </div>
</template>

<script>
import FieldGroup_input from "@/components/_base/FieldGroup_input.vue";

export default {
    name: "TableFilterInput",
    components: {FieldGroup_input},
    props:{
        object: Object,
        'modelValue': [Number, String, Array, Object],
    },
    data(){
        return{
          value: ''
        }
    },
    methods:{
        test(v){
            console.log(v)
        }
    }
}
</script>

<style scoped>

</style>