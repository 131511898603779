<template>
    <Chart :donut-center-render="'donutCenterRenderer'" :transitions="false" :ref="'chart'+index">
        <template v-slot:donutCenterRenderer>
            <div class="chart-donut__center" style="font: normal 400 12px Inter,sans-serif">
                <h3 class="chart-donut__center__title" v-if="centerTitle">
                    {{ $te(centerTitle) ? $t(centerTitle) : centerTitle }}
                </h3>
                <span class="chart-donut__center__subtitle">
                    {{ $te(''+centerSubtitle) ? $t(''+centerSubtitle) : centerSubtitle }}
                </span>
            </div>
        </template>
        <ChartTooltip :render="'renderTooltip'">
            <template v-slot:renderTooltip="{ props }">
                <div v-if="props && props.point.category" style="color: white">
                    <h2 class="border border__bottom custom-tooltip" v-if="tooltipItems(props)?.tooltipTitle">
                        {{ tooltipItems(props)?.tooltipTitle}}
                    </h2>
                    <ul>
                        <template v-for="(item,i) in tooltipItems(props)?.tooltipItemList" :key="i">
                            <li style="white-space: pre-line"
                                v-html="item"
                                v-if="item"
                            >
                            </li>
                        </template>
                    </ul>
                </div>
            </template>
        </ChartTooltip>
        <ChartTitle :text="$te(chartTitle) ? $t(chartTitle) : chartTitle" :font="'normal 700 16px Inter,sans-serif'" :color="'#415C84'" v-if="chartTitle"/>
        <ChartLegend
            :visible="showLegend"
            :position="chartLegendPosition"
            :orientation="orientation"
            :margin="marginLegend"
            :offset-x="0"
            :offset-y="10"
            :labels="{
                font: 'normal 400 12px Inter,sans-serif',
                color: '#415C84',
            }"
        >
            <ChartLegendTitle :text="$te(legendTitle) ? $t(legendTitle) : legendTitle" :font="'normal 700 16px Inter,sans-serif'" :color="'#415C84'" :padding="10" :align="'left'" v-if="legendTitle"/>
            <ChartLegendItem :style="{fontSize: '30px'}" />
        </ChartLegend>
        <ChartArea :background="'none'" />
        <ChartSeries>
            <ChartSeriesItem
                :type="type"
                :startAngle="0"
                :name="dataItems.name"
                :data-items="dataItems.data"
                :field="field"
                :category-field="categoryField"
                :color-field="'color'"
            >
                <ChartSeriesLabels
                    :position="'center'"
                    :color="'#fff'"
                    :background="'none'"
                    :font="'normal Bold 12px Inter,sans-serif'"
                    :content="labelContent"
                />
            </ChartSeriesItem>
        </ChartSeries>
    </Chart>
</template>

<script>
import {
    Chart,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartSeriesLabels,
    ChartLegendTitle,
    ChartTooltip,
    ChartTitle,
    ChartArea,
    ChartLegendItem
} from "@progress/kendo-vue-charts";
import "hammerjs";
const labelContent = (e) => {
    if(e.percentage <= 0.05) return '';
    return ((e.dataItem.value > 0) ? e.value : '')
};

export default {
    name: "Chart_donut",
    inject:['pdfExportParams'],
    props:{
        'dataItems': [Array, Object],
        'index': [String, Number],
        'marginLegend': Object,
        'type': String,
        'colorField': String,
        'tooltipTitle': String,
        'categoryField': {
            type: String,
            default: () => { return 'name' }
        },
        'field': {
            type: String,
            default: () => { return 'value' }
        },
        'showLegend': {
            type: Boolean,
            default: () => { return true }
        },
        "legendTitle": String,
        'labelsBackground': {
            type: String,
            default: () => { return 'none' }
        },
        'labelsColor': {
            type: String,
            default: () => { return '#fff' }
        },
        'chartLegendPosition': {
            type: String,
            default: () => { return 'right' }
        },
        'orientation':{
            type: String,
            default: () => { return 'vertical' }
        },
        'centerTitle': String,
        'centerSubtitle': [String, Number],
        'chartTitle': String,
    },
    components:{
        Chart,
        ChartLegend,
        ChartSeries,
        ChartSeriesItem,
        ChartSeriesLabels,
        ChartLegendTitle,
        ChartTooltip,
        ChartTitle,
        ChartArea,
        ChartLegendItem,
    },
    data(){
        return {
            labelContent,
        }
    },
    methods:{
        tooltipItems(props) {
            return this.dataItems?.data?.find(d => d.name == props.point.category) || {}
        },
    },
    mounted() {
        if(this.pdfExportParams) {
            this.pdfExportParams.push(this.$refs['chart'+this.index])
        }
    }
}
</script>

<style scoped>
.chart-donut__center__title,
.chart-donut__center__subtitle{
    font-family: Inter, Sans-serif;
    font-weight: 700;
    font-size: 12px;
    color: #415C84;
    text-align: center;
}
.custom-tooltip {
    font-style: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
    color: inherit;
    padding: inherit;
    margin: inherit;
}
</style>