<template>
    <section class="section__body">

        <!--- Navigation --->
        <nav class="section__navigation pfull__12">
            <!--Navigation-->
        </nav>

        <!--- Container --->
        <section class="section__container container container_active separator-when-scrolling plr__12 pb__12">
            <!-- Empty Container-->
        </section>
    </section>
</template>

<script>

</script>

<style scoped>

</style>