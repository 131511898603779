<template>
    <section class="section section_type_basic is_new-design" id="section_page">
        <!--- Section type Info --->
        <SectionInfoByRoute v-show="!isGuest && hasInfoByRoute" />

        <SectionInfo v-show="!isGuest && hasInfo" />

        <!--- Section type Content --->
        <SectionContent />

        <!--- Section type popup --->
        <teleport to="body">
            <SectionPopup v-show="!isGuest && hasPopup"/>
            <SectionPopupByRoute v-show="!isGuest && hasPopupByRoute"/>
        </teleport>


        <!--- Section type Wrapper-curtain + Section type Widget --->
        <SectionWidgetByRoute v-show="!isGuest && hasWidgetByRoute" />

        <SectionWidget v-show="!isGuest && hasWidget" />
    </section>
</template>

<script>
    import SectionInfoByRoute from './SectionInfoByRoute.vue'
    import SectionInfo from './SectionInfo.vue'
    import SectionContent from './SectionContent.vue'
    import SectionWidgetByRoute from './SectionWidgetByRoute.vue'
    import SectionWidget from './SectionWidget.vue'
    import SectionPopup from './SectionPopup'
    import SectionPopupByRoute from './SectionPopupByRoute'
    import {mapGetters} from "vuex";

    export default {
        name: "SectionBasic",
        // props: ['app'],
        components: {
            SectionInfoByRoute,
            SectionWidgetByRoute,
            SectionPopupByRoute,
            SectionContent,
            SectionInfo,
            SectionWidget,
            SectionPopup
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "apiToken",
                "isGuest",
            ]),
            routeMeta() {
              return this.$route.meta
            },
            hasInfo() {
                return true
            },
            hasInfoByRoute() {
                return true
            },
            hasWidget() {
                return true
            },
            hasWidgetByRoute() {
                return true
            },
            hasPopup() {
                return true
            },
            hasPopupByRoute() {
                return true
            },
        },
        mounted() {
        },
    }
</script>

<style lang="scss" scoped>
.section {
    &.section_type_basic {
        //display: grid;
        //grid-template-columns: auto 1fr;
        display: flex;
        width: 100%;
        position: relative;
    }
}
</style>