<template>
    <div class="list__sensors">
        <ul class="list__sensors__body">
            <template v-for="sensor in sensors" :key="sensor">
                <component :is="'UnitsList_unit_sensors_'+sensor"/>
            </template>
        </ul>
    </div>
</template>

<script>
    import UnitsList_unit_sensors_temperature from "@/components/units/UnitsList_unit_sensors_temperature.vue"
    import UnitsList_unit_sensors_humidity from "@/components/units/UnitsList_unit_sensors_humidity.vue"
    import UnitsList_unit_sensors_shock from "@/components/units/UnitsList_unit_sensors_shock.vue"

    export default {
        name: "UnitsList_unit_sensors",
        props: {
            'unitId': [Array, String],
            'sensors': {
                type: Array,
                default: () => []
            }
        },
        components:{
            UnitsList_unit_sensors_temperature,
            UnitsList_unit_sensors_humidity,
            UnitsList_unit_sensors_shock,
        },
        data() {
            return {}
        },
        computed: {
        },
        methods: {
        },
        updated: function() {
            // console.log(`unit status ${this.unitId} updated`);
        },
    }
</script>

<style scoped>

</style>