<template>
    <div :class="classUnitInfoControl">
        <span v-for="arrow in arrows" :key="arrow"></span>
    </div>
</template>

<script>
export default {
    name: "MovingArrow",
    props:{
        arrowsCount:{
            type: Number,
            default: () => { return 2 }
        },
        direction:{
            type: String,
            default: () => { return 'down'}
        }
    },
    computed:{
        arrows(){
            let arrows = []
            for(let i = 1; i <= this.arrowsCount ; i++){
                arrows.push(i)
            }
            return arrows
        },
        classUnitInfoControl(){
            return  'arrow-' + this.direction
        },
    }
}
</script>

<style lang="scss" scoped>
.arrow-down {
    width: 25px;
    height: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
        display: block;
        width: 5px;
        height: 5px;
        border-bottom: 2px solid #337AB7;
        border-right: 2px solid #337AB7;
        transform: rotate(45deg);
        animation: arrow-down 2s infinite;
        margin-top: 0;
        &:nth-child(2) {
            animation-delay: -0.2s;
        }
        &:nth-child(3) {
            animation-delay: -0.4s;
        }
    }
}
.arrow-up {
    width: 25px;
    height: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform-origin: center center;
    transform: translate(50%) rotate(180deg);
    span {
        display: block;
        width: 5px;
        height: 5px;
        border-bottom: 2px solid #337AB7;
        border-right: 2px solid #337AB7;
        transform: rotate(-45deg);
        animation: arrow-down 2s infinite;
        margin-top: 0;
        &:nth-child(2) {
            animation-delay: -0.2s;
        }
        &:nth-child(3) {
            animation-delay: -0.4s;
        }
    }
}
@keyframes arrow-down {
    0%{
        opacity: 0;
        transform: rotate(45deg) translate(0,0);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: rotate(45deg) translate(10px,10px);
    }
}
</style>