import consts from "@/consts";
import Client from 'shopify-buy';
const dealer = /*Client.buildClient*/(consts?.shopify?.dealer);
const client = /*Client.buildClient*/(consts?.shopify?.client);

function openCartWithProducts(shop, products, recipient='') {
    let shop_url = `https://${shop.domain}/`
    let client = Client.buildClient(shop);
    const shopPromise = client.shop.fetchInfo();
    const productsPromise = client.product.fetchAll();
    const cartPromise = client.checkout.create()
    let cartId;
    return Promise.all([productsPromise, cartPromise, shopPromise])
        .then(([shopProducts, cart, shop]) => {
            if(this.$isDevelopment) console.log(products, cart, shop)
            cartId = cart.id
            shopProducts = shopProducts.map(product => {
                let variant = client.product.helpers.variantForOptions(product, {Title: 'Default Title'})
                return {
                    id: product.id,
                    //options: product.options[0],
                    //variant: product.variants[0],
                    sku: product.variants[0]?.sku || '',
                    variantId: variant.id,
                }
            })
            products = products.map(product => {
                let shopProduct = shopProducts.find(shopProduct => {
                    return product.sku == shopProduct.sku
                })
                if(!shopProduct) {
                    console.warn('shopProduct not found', product, shopProducts)
                    return null
                }
                let quantity = product?.count || 0
                let customAttributes = product?.customAttributes || []
                return {variantId: shopProduct.variantId, quantity, customAttributes}
            }).filter(product => !!product)
            if(this.$isDevelopment) console.log(products, cart, shop)
            if(!products.length) return false
            return client.checkout.addLineItems(cartId, products)
        })
        .then((checkout) => {
            if(!checkout) return null
            return client.checkout.updateAttributes(cartId, {customAttributes: [{key: "recipient", value: recipient}]})
        })
        .then((checkout) => {
            let url = checkout?.webUrl || shop_url
            window.open(url, '_blank')
        })
        .catch((err) => {
            console.error(err)
            return err;
        })
}

const shopify = {dealer, client, openCartWithProducts}
export default shopify;
