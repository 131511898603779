<template>
    <span class="copy-text">
        <button class="button button_command copy-text__button"
                style="float: right;"
                @click="copyText(text)">
            <SvgIcon name="common__copy"/>
        </button>

        {{text}}
    </span>
</template>

<script>
import {copyTextToClipboard} from "@/lib/lib";
import {useToast} from "vue-toastification";

export default {
    name: "CopyTextComponent",
    props: {
        text: String,
    },
    data() {
        return {
            toast: useToast()
        }
    },
    methods: {
        copyText(text) {
            copyTextToClipboard(text)
                .then(() => {
                    this.toast.success(this.$t('Copy done'));
                })
                .catch((er) => {
                    this.toast.warning(this.$t('errors.Copy error, please copy manually'));
                    console.error('copy error', er);
                })
        },
    }
}
</script>

<style lang="scss" scoped>
.copy-text {
    display: block;
    align-items: center;
    &__button {
        color: inherit;
        position: relative;
    }
}
</style>