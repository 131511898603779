<template>
    <div class="list__item item_view_unit list-units-scroller-item-height"
         :class="{
            'item_active': isActive,
            'item_inactive': !isLicenseActive,
            'item_view_unit__group': subUnits.length
         }"
         :data-group-id="groupId" :data-unit-id="unitId"
         @click.stop="setUnitActive"
         @mouseover="showMenu = true" @mouseleave="showMenu = false"
    >
        <UnitsList_unit_icons :unitId="unitId" :indicators="indicators"/>

        <div class="list__title-line">
            <h4 class="list__title">{{unitName}}</h4>
            <UnitsList_unit_timeago :unit-id="unitId" v-if="false"/><!-- ! -->
            <UnitsList_unit_camera_status class="camera-status" :unit-id="unitId" v-if="hasVideo"/>
        </div>
        <div class="list__address-line">
            <UnitsList_unit_address :unit-id="unitId" /><!-- ! -->
            <UnitsList_unit__status :unit-id="unitId" v-if="false"/><!-- ! -->
        </div>
        <div class="item_view_unit__dropdown" v-if="subUnits.length && false">
            <button class="button button_command" @click="showSubUnits = !showSubUnits">
                <SvgIcon :name="showSubUnitsButtonIcon" class="icon"/>
            </button>
        </div>
        <UnitsList_unit_menu
            :unit-id="unitId"
            v-if="isLicenseActive && (showMenu || modalsWindows?.popupOutput ) && false"
        />
    </div>
    <div class="item_view_unit__group--active" v-show="showSubUnits" v-if="subUnits.length">
        <template v-for="unit in subUnits" :key="unit.unitId">
            <component :is="'UnitsList_unit'+unit.type" v-bind="unit"/>
        </template>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

import UnitsList_unit_timeago from "@/components/units/UnitsList_unit_timeago.vue";
import UnitsList_unit_address from "@/components/units/UnitsList_unit_address.vue";
import UnitsList_unit__status from "@/components/units/UnitsList_unit__status.vue";
//import UnitsList_unit_menu from "@/components/units/UnitsList_unit_menu.vue";
import UnitsList_unit_menu from "@/components/units/UnitsList_unit_menu.intuit.vue";
import UnitsList_unit_icons from "@/components/units/UnitsList_unit_icons.prometheus.vue";
import {computed} from "vue";

//unit types
import UnitsList_unit from "@/components/units/UnitsList_unit.prometheus.vue"
import UnitsList_unit_xpower from "@/components/units/UnitsList_unit.prometheus.vue"
import UnitsList_unit_xlink from "@/components/units/UnitsList_unit.prometheus.vue"
import UnitsList_unit_xtag from "@/components/units/UnitsList_unit.prometheus.vue"
import UnitsList_unit_camera_status from "@/components/units/UnitsList_unit_camera_status.prometheus.vue";
let indicatorsByStatus = {
    default: ['connection', 'moving', 'ignition'],
    video: ['connection', 'moving', 'ignition'],//'camera', 'ignition', 'moving', 'text', 'video', 'outputs'
    reefer: ['connection', 'moving'],//'ignition', 'moving', 'text', 'reefer', 'outputs'
}

export default {
    name: 'UnitsList_unit',
    inject: ['setRoute'],
    components: {
        UnitsList_unit_icons,
        UnitsList_unit_address,
        UnitsList_unit_timeago,
        UnitsList_unit__status,
        UnitsList_unit_menu,
        UnitsList_unit,
        UnitsList_unit_xpower,
        UnitsList_unit_xlink,
        UnitsList_unit_xtag,
        UnitsList_unit_camera_status,
    },
    props: {
        'unitId': [String, Number],
        'groupId': [String, Number],
    },
    data() {
        return {
            modalsWindows: {},
            showMenu: false,
            showSubUnits: false,
        }
    },
    provide() {
        return {
            modalsWindows: computed(() => this.modalsWindows)
        }
    },
    computed: {
        ...mapGetters([
            "getTimeToday",
            "unitsByIds",
            "getUnitLicenseById",
            // "groupsShowUnits",
            // "groupsShowUnitsByIds",

            "isUnitsLmsgsFullLoad",
            "isUnitsFullLoad",
            "units",
        ]),
        routeName() {
            return this.$route.name
        },
        routeFilter() {
            return this.$route.params
        },
        routeQueryFilter() {
            return this.$route.query
        },
        unit(){
            return this.unitsByIds[this.unitId]
        },
        unitName(){
            return this.unit?.name || ''
        },
        isLicenseActive(){
            return this.getUnitLicenseById[this.unitId]?.isActive
        },
        isReefer() {
            return this.unit?.hw_type == 'globe_tracker'
        },
        hasVideo() {
            return !!this.unit?.video
        },
        indicators() {
            let status = 'default'
            if(this.isReefer) status = 'reefer'
            else if(this.hasVideo) status = 'video'
            return indicatorsByStatus[status]
        },
        isActive(){
            return this.routeFilter.unitId == this.unitId
        },
        showSubUnitsButtonIcon() {
            return `vector-` + (this.showSubUnits ? 'top' : 'down')
        },
        subUnits() {
            return this.units
                .filter(u => u.parent__id === this.unitId)
                .map(unit => {
                    let type = unit?.hw_type
                    type = this.$consts.unitsList.useTypeForTemplate.includes(type) ? '_'+type : ''
                    return {xid: this.groupId + unit.id, groupId: this.groupId, type, unitId: unit.id, parentId: this.unitId}
                })
        }
    },
    watch: {
        isActive(newVl) {
            if (!newVl) {
                this.showSubUnits = false
            }
        },
    },
    methods: {
        ...mapMutations([
        ]),
        ...mapActions([
        ]),
        setUnitActive(){
            this.setRoute.unitId(this.$route, this.$router, this.unitId)
        },
    },
    updated() {
        // console.log(`unit ${this.unitId} updated`);
    },
    mounted() {
    },
    beforeUnmount() {
    },
}
</script>
