<template>
    <div class="symbol__image-status flex align-items-canter flex-gap-5" :class="ignition.icon_class">
        <SvgIcon class="icon" :name="ignition.icon"/>
        <span class="unit-indicators__text" v-if="text">{{ $t('Units.movements.' + ignition.text) }}</span>
    </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
    setup() {
        return {
            statuses: {
                on: {
                    bg_icon: '',
                    icon_class: 'connection_status-online',
                    icon: 'ignition-on',
                    text: 'Ignition on'
                },
                off: {
                    bg_icon: '',
                    icon_class: 'connection_status_not-online-long-time',
                    icon: 'ignition-off',
                    text: 'Ignition off'
                },
            },
        }
    },
    name: 'UnitInfo_indicator_ignition',
    props: {
        unitId: [String, Number],
        text: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters([
            "unitsLmsgsByIds",
            "unitsLmsgsTimeAgoByIds",
        ]),
        unitLmsg() {
            return this.unitsLmsgsByIds[this.unitId]
        },
        ignition() {
            let ignition = !!this.unitLmsg?.status?.ignition?.value
            return this.statuses[ignition ? 'on' : 'off']
        },
    },
    updated: function () {
        // console.log(`UnitsList_unit_moving_status ${this.isMoving} updated`);
    },
}
</script>