<template>
    <section
        class="section section_type_info is_new-design"
        id="sectionInfo"
        :class="{'section_status_open': showSectionInfo}"
    >
        <component :is="sectionInfo_component"
                   v-bind="sectionInfo_props"
                   v-on:close-dialog="onClose"
        />
    </section>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import {mapActions, mapGetters} from "vuex"
import emptySection from "@/components/app/emptySection.vue"
import UserSettings from "@/components/app/sectionInfo/UserSettings.vue"
// import UserChangePassword from "@/components/app/sectionInfo/UserChangePassword.vue";
// import Payment from "@/components/app/sectionInfo/Payment.vue"

export default {
    name: "SectionInfo",
    props: [],
    components: {
        emptySection,
        UserSettings,
        'UserChangePassword':  defineAsyncComponent(() => import('@/components/app/sectionInfo/UserChangePassword.vue')),
        'UserUpgrade':  defineAsyncComponent(() => import('@/components/app/sectionInfo/UserUpgrade.vue')),
    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters([
            "showSectionInfo",
            "getSectionInfo",
        ]),
        routeFilter() {
            return this.$route?.params
        },
        routeMeta() {
            return this.$route.meta
        },
        sectionInfo() {
            return (this.getSectionInfo || {component: 'emptySection', props: {}})
        },
        sectionInfo_component() {
            return this.getSectionInfo ? this.getSectionInfo.component : 'emptySection'
        },
        sectionInfo_props() {
            return this.getSectionInfo ? this.getSectionInfo.props : {}
        },
    },
    methods: {
        ...mapActions([
            "setSectionInfo",
        ]),
        onClose(){
            this.setSectionInfo(null)
        },
    },
    updated: function() {
        //console.log('SectionInfo updated', this.sectionInfo_component, {...this.sectionInfo_props});
    },
    mounted() {
        // console.log('section_type_info', this)
    },
}
</script>

<style scoped>

</style>