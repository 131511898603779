import consts from "@/consts"
import {getApiProps} from "@/lib/lib";
import range from "lodash/range";
// import range from "lodash/range";
// import {objectComparison} from "@/lib/lib";
const changedField = 'changed__time';

export default {
    state: {
        customizesLiteLoad: false,
        customizesFullLoad: false,
        customizes: [],
    },
    actions: {
        fetchUsersCustomizes/*all*/({dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchUsersCustomizes', time: Date.now() / 1000})

                const params = getApiProps('customizes', args)
                this.$api.customize.getAll({...params})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchUsersCustomizes', inprogress: false})
                    });
            })
        },
        fetchUsersCustomizesAll({dispatch}) {
            return dispatch('fetchUsersCustomizesAllLite')
        },
        fetchUsersCustomizesAllLite({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isUsersCustomizesLiteLoad) {
                    return resolve(getters.getUsersCustomizes.length)
                }
                dispatch('fetchUsersCustomizes', {lite: true})
                    .then((data) => {
                        commit('updateCustomizes', data)
                        commit('setCustomizesLiteLoad', true)
                        resolve(data.length)
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchUsersCustomizesAllLite', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchUsersCustomizesAllPages({dispatch, commit, getters}) {
            //dispatch('setLastCall', {name: 'fetchUsersCustomizesAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchUsersCustomizesChanged', time: Date.now() / 1000})

            return new Promise((resolve, reject) => {
                    if (!getters.apiToken) {
                        return reject(null)
                    }
                    if (!getters.getUsersCustomizes.length) {
                        return resolve([])
                    }
                    let pageSize = consts.querySettings.pageSize
                    let pages = Math.ceil(getters.getUsersCustomizes.length / pageSize)
                    let fetch = range(pages).map(i => {
                        let page = i + 1;
                        return dispatch('fetchUsersCustomizes', {page, 'page-size': pageSize})
                            .then((data) => {
                                commit('updateCustomizes', data)
                            })
                            .catch(() => {
                                dispatch('fetchUsersCustomizes', {page, 'page-size': pageSize})
                            })
                    });
                    resolve(fetch)
                })
                .then((fetch) => {
                    return Promise.all(fetch)
                        .finally(() => {
                            commit('setCustomizesFullLoad', true)
                        })
                })
                .then(() => {
                    //dispatch('setLastCall', {name: 'fetchUsersCustomizesAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchUsersCustomizesChanged', inprogress: false})
                })
        },
        fetchUsersCustomizesChanged({dispatch, commit, getters}, args) {
            if (!getters.apiToken || !getters.isUsersCustomizesFullLoad) {
                return
            }
            dispatch('setLastCall', {name: 'fetchUsersCustomizesChanged', time: Date.now() / 1000})

            args = {...consts.querySettings.filter, ...args}
            return dispatch('fetchUsersCustomizes', args)
                .then((data) => {
                    commit('updateCustomizes', data)
                    return dispatch('fetchUsersCustomizes', {fields: 'id_user',expand: ''})
                })
                .then((data) => {
                    commit('filterCustomizes', data)
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchUsersCustomizesChanged', inprogress: false})
                });
        },
        reloadUsersCustomizesAll({commit, dispatch}, args) {
            commit('clearUsersCustomizes')
            return dispatch('fetchUsersCustomizesAllLite', args)
                .then(() => {
                    dispatch('fetchUsersCustomizesAllPages', args)
                })
        },

        fetchUserCustomize({ commit, getters }, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken || !args.id_user) {
                    return reject(false)
                }
                const params = getApiProps('customizes', args)
                this.$api.customize.find(args.id_user, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateCustomize', response.data)
                            resolve(true)
                        } else {
                            console.error(response);
                            reject(false)
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(false)
                    });
            });
        },

        updateCustomize({ commit, dispatch }, customize) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('customizes')
                this.$api.customize.update(customize.id_user, customize, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('updateCustomize', response.data)
                            dispatch('fetchUsersCustomizesChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

    },
    mutations: {
        setCustomizesLiteLoad(state, LiteLoad) {
            state.customizesLiteLoad = state.customizesLiteLoad || LiteLoad
        },
        setCustomizesFullLoad(state, FullLoad) {
            state.customizesFullLoad = state.customizesFullLoad || FullLoad
        },
        updateCustomizes(state, nCustomizes) {
            if(!state.customizes.length) {
                state.customizes = nCustomizes.map(u => Object.freeze(u))
                return true
            }

            // console.log('updateCustomizes', state.customizes.length)
            nCustomizes.forEach(function (nCustomize) {
                let i = state.customizes.findIndex(u => (u.id_user == nCustomize.id_user))
                if(i<0) {
                    state.customizes.push(Object.freeze(nCustomize))
                }else
                if(!state.customizesFullLoad || state.customizes[i][changedField] != nCustomize[changedField]) {
                    // updateObjectByDiff(state.geopoints[i], nGeopoint)
                    delete nCustomize.id_user
                    state.customizes[i] = Object.freeze({...state.customizes[i], ...nCustomize})
                }
            })
        },
        filterCustomizes(state, nCustomizes) {
            // let Ids = state.customizes.map(u=> u.id_user)
            let nIds = nCustomizes.map(u => u.id_user)
            let removedIds = state.customizes.filter(u => !nIds.includes(u.id_user)).map(u => u.id_user)
            removedIds.forEach(removedId => {
                let i = state.customizes.findIndex(u => (u.id_user == removedId))
                if(i != -1) {
                    state.customizes.splice(i, 1)
                }
            })
        },

        updateCustomize(state, nCustomize) {
            let i = state.customizes.findIndex(u => (u.id_user == nCustomize.id_user))
            if(i<0) {
                state.customizes.push(Object.freeze(nCustomize))
            }else
            if(!state.customizesFullLoad || state.customizes[i][changedField] != nCustomize[changedField]) {
                // updateObjectByDiff(state.geopoints[i], nGeopoint)
                delete nCustomize.id_user
                state.customizes[i] = Object.freeze({...state.customizes[i], ...nCustomize})
            }
        },
        deleteCustomize(state, id_user) {
            let i = state.customizes.findIndex(u => (u.id_user == id_user))
            if(i != -1) {
                state.customizes.splice(i, 1)
            }
        },
        clearCustomizes(state) {
            state.customizes = []
            state.customizesLiteLoad = false
            state.customizesFullLoad = false
        },
    },
    getters: {
        isUsersCustomizesLiteLoad(state) {
            return state.customizesLiteLoad
        },
        isUsersCustomizesFullLoad(state) {
            return state.customizesFullLoad
        },
        getUsersCustomizes(state){
            return state.customizes
        },
        getUsersCustomizesByUsersIds(state){
            return state.customizes.reduce((customizesByIds, c) => {
                customizesByIds[c.id_user] = c
                return customizesByIds
            }, {})
        },
    }
}
