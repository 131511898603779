const RESOURCE_NAME = '';//'/auth/';
import axios from "@/api/http-auth";
import config from "@/config";
const baseURL = config.api.baseURL

export default () => ({
    getObjects(params) {
        return axios.get(`${RESOURCE_NAME}/init`, {params, baseURL});
    },
    getUnitsLmsgs(params) {
        return axios.get(`${RESOURCE_NAME}/units-lmsgs`, {params, baseURL});
    },
    getUnits(params) {
        return axios.get(`${RESOURCE_NAME}/units`, {params, baseURL});
    },
    getDrivers(params) {
        return axios.get(`${RESOURCE_NAME}/drivers`, {params, baseURL});
    },
    getAppointments(params) {
        return axios.get(`${RESOURCE_NAME}/appointments`, {params, baseURL});
    },
    getUnitsGroups(params) {
        return axios.get(`${RESOURCE_NAME}/units-groups`, {params, baseURL});
    },
    getUsers(params) {
        return axios.get(`${RESOURCE_NAME}/users`, {params, baseURL});
    },
    getUsersGroups(params) {
        return axios.get(`${RESOURCE_NAME}/users-groups`, {params, baseURL});
    },
    getGeopoints(params) {
        return axios.get(`${RESOURCE_NAME}/geopoints`, {params, baseURL});
    },
    getGeopointsGroups(params) {
        return axios.get(`${RESOURCE_NAME}/geopoints-groups`, {params, baseURL});
    },
    getGeozones(params) {
        return axios.get(`${RESOURCE_NAME}/geozones`, {params, baseURL});
    },
    getGeozonesGroups(params) {
        return axios.get(`${RESOURCE_NAME}/geozones-groups`, {params, baseURL});
    },
    getUnitsKeys(params) {
        return axios.get(`${RESOURCE_NAME}/units-keys`, {params, baseURL});
    },
    getUnitsKeysOrders(params) {
        return axios.get(`${RESOURCE_NAME}/units-keys-orders`, {params, baseURL});
    },
    getShipments(params) {
        return axios.get(`${RESOURCE_NAME}/shipments`, {params, baseURL});
    },
});