<template>
    <div class="info__time time"
         :class="[{'time_format_12-hour': time_format_12_hour}, controlClass]"
    >
        {{ time }}
    </div>
</template>
<script>

import {mapGetters} from "vuex";
const moment = require('moment-timezone');
// moment.tz.load({ });

export default {
    name: 'SectionCurrentDateTime',
    props: {
        controlClass: [String, Object, Array],
        timezoneOffset: {
            type: Number,
        },
        format:{
            type: String,
            defoult: () => { return 'HH:mm' }//'hh:mm A'
        }
    },
    data() {
        return {
            //jsTZ: new Date().getTimezoneOffset() * 60,
            now: Math.round(Date.now() / 1000),
            time_format_12_hour: true,
            //format: 'HH:mm A',
            interval: null,
        }
    },
    components: {},
    computed: {
        ...mapGetters([
            "getAppUser",
        ]),
        time() {
            return moment(this.now*1000).utcOffset(this.timezoneOffset/3600).format(this.format)
            // let seconds = +this.now + this.jsTZ + this.timezoneOffset
            // return new Date(seconds * 1000)
            //     .toLocaleTimeString('en-US', {
            //         hour: '2-digit', minute: '2-digit',
            //         hour12: this.time_format_12_hour
            //     });
        },
    },
    mounted() {
        this.now = Math.round(Date.now() / 1000)
        // this.interval = setInterval(() => {
        //     this.now = Math.round(Date.now() / 1000)
        // }, 1000)
        let t = 61 - (this.now % 60)
        setTimeout(() => {
            this.now = Math.round(Date.now() / 1000)
            this.interval = setInterval(() => {
                this.now = Math.round(Date.now() / 1000)
            }, 60*1000)
        }, t*1000)
    },
    beforeUnmount() {
        if(this.interval) {
            clearInterval(this.interval)
            this.interval = null
        }
    },
}
</script>