<template>
    <section
            class="section section_type_wrapper-curtain"
            :class="{'section_status_open-widget': showSectionWidgetByRoute,}"
    >
        <section class="section section_type_widget is_new-design" id="sectionWidgetByRoute"></section>
    </section>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "SectionWidgetByRoute",
        props: [],
        components: {
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
            ]),
            routeMeta() {
                return this.$route.meta
            },
            showSectionWidgetByRoute() {
                return this.routeMeta.sectionWidgetByRoute
            },
        },
        methods: {
            ...mapActions([
            ]),
        },
        updated: function() {
            //console.log('SectionWidgetByRoute updated', this.sectionWidget_component);
        },
        mounted() {
            // console.log('section_type_info', this)
        },
    }
</script>

<style scoped>

</style>