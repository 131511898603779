<template>
    <section class="section__info pfull__16" :class="class_type">
        <div class="vertical-line">
            <div class="parameter mb__12">
                <span class="parameter__icon icon icon_type_circle icon_theme_secondary"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__signal"/></span> </span>
                <h5 class="parameter__label">{{ $t('MapInfo.Online') }}</h5>
                <p class="parameter__value">{{online}}</p>
            </div>

            <div class="parameter">
                <span class="parameter__icon icon icon_type_circle icon_theme_danger"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__signal"/></span> </span>
                <h5 class="parameter__label">{{ $t('MapInfo.Offline') }}</h5>
                <p class="parameter__value">{{offline}}</p>
            </div>
        </div>

        <div class="vertical-line">
            <div class="parameter mb__12">
                <span class="parameter__icon icon icon_type_circle icon_theme_secondary"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__movement"/></span> </span>
                <h5 class="parameter__label">{{ $t('MapInfo.Moving') }}</h5>
                <p class="parameter__value">{{moving}}</p>
            </div>

            <div class="parameter">
                <span class="parameter__icon icon icon_type_circle"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__parking"/></span> </span>
                <h5 class="parameter__label">{{ $t('MapInfo.Parked') }}</h5>
                <p class="parameter__value">{{parked}}</p>
            </div>
        </div>

        <div class="vertical-line">
            <div class="parameter mb__12">
                <span class="parameter__icon icon icon_type_circle"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__shown"/></span> </span>
                <h5 class="parameter__label">{{ $t('MapInfo.Shown') }}</h5>
                <p class="parameter__value">{{shown}}</p>
            </div>

            <div class="parameter">
                <span class="parameter__icon icon icon_type_circle"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__hidden"/></span> </span>
                <h5 class="parameter__label">{{ $t('MapInfo.Hidden') }}</h5>
                <p class="parameter__value">{{hidden}}</p>
            </div>
        </div>

        <div class="vertical-line">
            <div class="parameter mb__12">
                <span class="parameter__icon icon icon_type_circle"> <span class="icon__symbol"><SvgIcon class="icon" name="circle___geozone_circle"/></span> </span>
                <h5 class="parameter__label">{{ $t('MapInfo.Geo shown') }}</h5>
                <p class="parameter__value">{{geo_shown}}</p>
            </div>

            <div class="parameter">
                <span class="parameter__icon icon icon_type_circle"> <span class="icon__symbol"><SvgIcon class="icon" name="circle___geozone_circle_hidden"/></span> </span>
                <h5 class="parameter__label">{{ $t('MapInfo.Geo hidden') }}</h5>
                <p class="parameter__value">{{geo_hidden}}</p>
            </div>
        </div>

        <div class="vertical-line vertical-line_type_tracking type_tracking"
             :class="{type_tracking_status_active: unitIdByRoute }"
             v-if="false"
        >
            <div class="parameter mb__12">
                <span class="parameter__icon icon icon_type_circle"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__coords"/></span> </span>
                <h5 class="parameter__label">{{ $t('MapInfo.Tracking unit') }}</h5>
                <p class="parameter__value">{{unit.name}}</p>
            </div>
            <button class="button button_view_base button_theme_neutral button_size_md"
                    @click="setRoute.units(this.$route, this.$router)"
            >
                {{ $t('buttons.Cancel') }}
            </button>
        </div>
    </section>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'SectionMapUnitsInfo',
    props: {
        'class_type':{
            type: String,
            default: () => { return '' }
        }
    },
    data() {
        return {}
    },
    components: {},
    computed: {
        ...mapGetters([
            "units",
            "unitsOnMap",
            "unitsIdsIsActive",
            "unitsIdsIsNotActive",
            "unitsIdsIsMoving",
            "unitsIdsIsParked",
            "unitsIdsIsIgnitionOn",
            // "unitsOnMap",
            "unitsByIds",
            "geozones",
            "geoitemsOnMap"
        ]),
        setRoute() {
            return this.$route.setRoute?.setRoute
        },
        unitIdByRoute() {
            return this.$route.params?.unitId
        },
        unit(){
            return this.unitsByIds[this.unitIdByRoute] || {}
        },
        online(){
            return this.unitsIdsIsActive.length
        },
        offline(){
            //return this.unitsIdsIsNotActive.length
            return this.units.length - this.online
        },
        moving(){
            return this.unitsIdsIsMoving.length
        },
        parked(){
            //return this.unitsIdsIsParked.length
            return this.units.length - this.moving
        },
        ignitionOn(){
            return this.unitsIdsIsIgnitionOn.length
        },
        shown(){
            return this.unitsOnMap.length || (this.unitIdByRoute ? 1 : 0)
        },
        hidden(){
            return this.units.length - this.shown
        },
        geo_shown(){
            return this.geoitemsOnMap.length
        },
        geo_hidden(){
            return this.geozones.length - this.geo_shown
        },
    },
    methods: {
        ...mapActions([
        ]),
    },
    updated() {
    },
    mounted() {
    },
    beforeUnmount() {
    },
}
</script>

<style scoped>

</style>