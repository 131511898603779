const routes = [
    // {
    //     path: '/test-page',
    //     name: 'TestPage',
    //     meta: { isGuest: true, hideMap: true},
    //     component: () => import('@/views/test/TestPage.vue')
    // },

    {
        path: '/login',
        name: 'Login',
        meta: {isGuest: true, hideMap: true},
        component: () => import('@/views/LoginPage.vue')
    },
    {
        path: '/request-password-reset',
        name: 'RequestPasswordReset',
        meta: {isGuest: true, hideMap: true},
        component: () => import('@/views/RequestPasswordResetPage.vue')
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        meta: {isGuest: true, hideMap: true},
        component: () => import('@/views/ResetPasswordPage.vue')
    },
    // {
    //   path: '/signup',
    //   name: 'Signup',
    //   meta: {isGuest: true, hideMap: true},
    //   component: () => import('@/views/SignupPage.vue')
    // },
    // {
    //   path: '/verify-email',
    //   name: 'VerifyEmail',
    //   meta: {isGuest: true, hideMap: true},
    //   component: () => import('@/views/VerifyEmailPage.vue')
    // },
    // {
    //   path: '/resend-verification-email',
    //   name: 'ResendVerificationEmail',
    //   meta: {isGuest: true, hideMap: true},
    //   component: () => import('@/views/ResendVerificationEmailPage.vue')
    // },

]

export default routes