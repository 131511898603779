<template>
    <div class="filter-template">
        <div class="horizontal-line">
            <div class="no-wrap-items">
                <FieldGroup_flatpickr
                    class="select-wrap-web"
                    :field-group_class="'field-group_view_calendar field-group_view_table field-group__icon-50 field-group_view_compact'"
                    :input_icon="'input__date'"
                    :max-date="maxDate"
                    v-model="date"
                />
                <button class="button button-no-style" @click="[clearFilterField(object.field), date = '']" v-if="showClearFilter">
                    <span role="presentation" class="k-icon k-button-icon k-i-filter-clear"></span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>

import moment from "moment";
import {mapGetters} from "vuex";

export default {
    name: "TableFilterDate",
    props: {
        'object': Object,
        'showClearFilter': {
            type: Boolean,
            default : () => true
        },
        'clearFilterField': Function,
        'modelValue': [Number, String, Array, Object],
        'maxDate': [String, Number],
    },
    data(){
        return {
            date: '',
        }
    },
    computed:{
        ...mapGetters([
            "getDateFormat",
        ])
    },
    watch:{
        date: {
            handler: function (nVal) {
                this.setObjectChange(nVal)
            }
        }
    },
    methods:{
        formatDate(data) {
            if (!(data > '')) return ''
            return moment(data).format('YYYY-MM-DD');
        },
        setObjectChange(value) {
            this.object.onChange({
                operator: 'eq',
                field: this.object.field,
                value: this.formatDate(value) || undefined,
            });
        }
    }
}
</script>

<style scoped>

</style>