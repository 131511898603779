import axios from 'axios';
import consts from "@/consts"
import i18n from '@/i18n'

const instance = axios.create({
    //baseURL: config.api.baseURL,
    headers: {
        "Content-type": "application/json",
    }
})

instance.defaults.timeout = consts.api.defaults.timeout;

instance.interceptors.request.use(
    (config) => {
        config.headers['X-Accept-Language'] = i18n.global.locale || 'en'
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => {
        // if (response.status === 200) {
        //     return response;
        // }
        // return Promise.reject(response);
        return response;
    },
    error => {
        if (error) {
            const status = error?.response ? error.response?.status : -1
            if (status === 401) {
                //???
            }
            // console.error(error)
        }
        return Promise.reject(error);
    }
);

export default instance;