<template>
    <div class="section__head-item balance" ><!-- no v-if -->
        <span class="balance__title" v-if="!isSuperAdmin">{{ title ? title : $t('text.Balance')}}:</span>
        <ul class="balance__list" v-for="(product, i) in unitsKeysFreeSumText" :key="i">
            <li class="balance__item" v-for="(key, i) in product" :key="i">
                <template v-if="isIconView">
                    <div class="balance__icon-view">
                        <div class="balance__icon-view-icon">
                            <SvgIcon name="products_bg" class="icon" :style="{color: colorsByDays[key.days]}"/>
                            <span class="balance__icon-view-icon-logo">
                                <img :src="require('@/assets/img/product_logo.png')">
                            </span>
                            <span class="balance__icon-view-text">
                                {{ key.type == 'trial' ? $t('Keys.Type.'+key.type) : $t('License.' + labelsByDays[key.days]) }}
                            </span>
                        </div>
                        <span class="balance__count">{{$t('Keys.Type.'+key.type)}}: {{key.count}}</span>
                    </div>
                </template>
                <template v-else>
                    <span class="balance__name">{{key.name.replace('License', '').trim()}}:</span>
                    <span class="balance__count">{{key.count}}</span>
                </template>
            </li>
        </ul>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "SectionBalance",
    components: {},
    props: {
        title: String,
        isIconView: {
            type: Boolean,
            default: () => false
        }
    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters([
            "apiToken",
            "isGuest",
            "isDealer",
            "getAppUser",
            "getUserStorageKey",
            "isSuperAdmin",
            "unitsKeysFree",
            "unitsKeysFreeSum",
            "unitsKeysFreeSumText",
        ]),
        labelsByDays(){
            return this.$consts.Licenses.params.reduce((labelsByDays, p) => {
                labelsByDays[p.days] = p.text
                return labelsByDays
            }, {})
        },
        colorsByDays(){
            return this.$consts.Licenses.params.reduce((colorsByDays, p) => {
                colorsByDays[p.days] = p.color
                return colorsByDays
            }, {})
        },
    },
    created() {
        // console.log('SectionBalance created');
    },
    mounted() {
        // console.log('SectionBalance mounted');
    },
    updated() {
        // console.log('SectionBalance updated');
    },
}
</script>

<style lang="scss" scoped>
.balance{
    display: flex;
    align-items: center;
    overflow-x: auto;
    gap: 5px;
    width: 100%;
    @include scroll(var(--color-neutral-25), var(--color-neutral-100), .2rem);
    &__list{
        display: flex;
        gap: 5px;
        &:not(:last-child):after{
            content: ',';
            font-size: 14px;
            line-height: 20px;
            font-weight: 700;
            font-style: normal;
            text-transform: none;
            font-family: "Open Sans", sans-serif;
            color: var(--color-font-light);
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    &__title{
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        font-style: normal;
        text-transform: none;
        margin-right: 5px;
        font-family: "Open Sans", sans-serif;
        color: var(--color-font-light);
    }
    &__item{
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        font-style: normal;
        text-transform: none;
        font-family: "Open Sans", sans-serif;
        color: var(--color-font-light);
        display: flex;
        justify-content: center;
        align-items: center;
        &:not(:last-child):after{
            content: ',';
        }
    }
    &__name{
        margin-right: 5px;
        white-space: nowrap;
    }
    &__count {
        white-space: nowrap;
    }
    &__icon-view {
        display: flex;
        align-items: center;
        gap: 5px;
    }
    &__icon-view-icon {
        display: flex;
        align-items: center;
        position: relative;
        width: 50px;
        height: 50px;
        .icon {
            width: 50px;
            height: 50px;
        }
    }
    &__icon-view-icon-logo {
        display: flex;
        align-items: center;
        width: 21px;
        height: 21px;
        background: white;
        border-radius: 100%;
        position: absolute;
        top: -3px;
        left: -3px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    &__icon-view-text{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 75%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-60%) translateX(-50%);
        color: white;
        font-size: 9px;
        line-height: 10px;
    }
}
</style>