<template>
    <UnitInfo_indicator_camera :unitId="unitId"/>
</template>
<script>
    import UnitInfo_indicator_camera from "@/components/units/info/UnitInfo_indicator_camera.vue";

    export default {
        name: 'UnitsList_unit_camera_status',
        props: {
          unitId: [String, Number],
        },
        components:{
          UnitInfo_indicator_camera
        },
    }
</script>