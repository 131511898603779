<template>
    <ul class="unit-indicators">
        <template v-for="indicator in indicators" :key="indicator">
            <component :is="indicator" :unitId="unitId" :text="true" class="unit-indicators__item"/>
        </template>
    </ul>
</template>

<script>
import {defineAsyncComponent} from "vue";

export default {
    name: "UnitInfo_indicators",
    props: {
        unitId: [String, Number],
        indicators: {
            type: Array,
            default: () => ['connection', 'movement']
        }
    },
    components: {
        'connection': defineAsyncComponent(() => import('@/components/units/info/UnitInfo_indicator_connection.vue')),
        'movement': defineAsyncComponent(() => import('@/components/units/info/UnitInfo_indicator_movement.vue')),
        'camera': defineAsyncComponent(() => import('@/components/units/info/UnitInfo_indicator_camera.vue')),
        'ignition': defineAsyncComponent(() => import('@/components/units/info/UnitInfo_indicator_ignition.vue')),
    }
}
</script>

<style lang="scss">
.unit-indicators {
    @include font(12px, normal, 500, none, normal);
    .unit-indicators__text {
        font-size: 10px;
        color: var(--color-default);
    }
    .svg-icon {
        width: 22px;
        height: 22px;
    }
}
</style>