<template>
    <div class="symbol__image-status flex align-items-canter flex-gap-5" :class="params.icon_class">
        <SvgIcon class="icon" :name="params.icon"/>
        <span class="unit-indicators__text" v-if="text">{{ $t('Units.movements.' + params.text) }}</span>
    </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
    setup() {
        return {
            statuses: {
                moving: {
                    bg_icon: '',
                    icon_class: 'connection_status-online',
                    icon: 'status_move',
                    text: 'Moving',
                },
                parking: {
                    bg_icon: '',
                    icon_class: 'connection_status_blue',
                    icon: 'status_stop',
                    text: 'Parked',
                }
            }
        }
    },
    name: 'UnitInfo_indicator_movement',
    props: {
        unitId: {
            type: Boolean,
            default: () => false
        },
        text: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters([
            "unitsLmsgsByIds",
        ]),
        unitLmsg() {
            return this.unitsLmsgsByIds[this.unitId]
        },
        isMoving() {
            return this.unitLmsg?.status?.isMoving
        },
        params() {
            return this.statuses[this.isMoving ? 'moving' : 'parking']
        },
    },
    updated: function () {
        // console.log(`UnitsList_unit_moving_status ${this.isMoving} updated`);
    },
}
</script>