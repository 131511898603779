<template>
    <li class="list__sensors__body-item" v-if="temperature">
                <span class="list__sensors__body-item-icon">
                    <SvgIcon name="unit-list-temperature"/>
                </span>
        <span>
            {{ $t('Unit.sensors.temperature') }}:
            <span class="list__sensors__body-item-value">
                {{temperature}}
            </span>
        </span>
    </li>
</template>

<script>
    import {
        // mapActions,
        mapGetters,
    } from "vuex";

    export default {
        name: "UnitsList_unit_sensors_temperature",
        props: {
            'unitId': [Array, String],
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "unitsOnMap",
                "unitsLmsgsByIds",
                "getAppUserUnits"
            ]),
            unitLmsg() {
                return this.unitsLmsgsByIds[this.unitId]
            },
            temperature() {
                return this.unitLmsg?.sensors?.temperature?.text || '-'
            },
        },
        methods: {
            // ...mapActions([
            // ]),
        },
        updated: function() {
            // console.log(`unit status ${this.unitId} updated`);
        },
    }
</script>

<style scoped>

</style>