export default (axios, resource) => ({
    getAll(params) {
        return axios.get(`${resource}`, {params});
    },
    find(id, params) {
        return axios.get(`${resource}/${id}`, {params});
    },
    create(data, params) {
        return axios.post(`${resource}`, data, {params});
    },
    update(id, data, params) {
        return axios.put(`${resource}/${id}`, data, {params});
    },
    delete(id, params) {
        return axios.delete(`${resource}/${id}`, {params});
    },
});