const RESOURCE_NAME = '/reports';
import restApi from "@/api/rest-api-object";

export default axios => ({
    ...restApi(axios, RESOURCE_NAME),
    getSystemReportData(id, from, to, objType, objId, params) {
        return axios.get(`${RESOURCE_NAME}/${id}/${from}/${to}/${objType}/${objId}`, {params});
    },
    // exec(type, format, data, params) {
    //     return axios.post(`${RESOURCE_NAME}/`, data, {params});
    // },
    export(id, format, data, params) {
        return axios.post(`${RESOURCE_NAME}/${id}/${format}`, data, {params});
    },
});