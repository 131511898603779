// import store from '@/store';
// import moment from "moment";
import consts from "@/consts";
const routes = (process.env.VUE_APP_PACKAGE !== 'prometheus.portal') ? [] : [
    {
        path: '/maintenance',
        name: consts.routerNames.maintenance.main,
        meta: {
            list: 'MaintenanceList',
            mainRouteName: consts.routerNames.maintenance.main,
            showMap: true
        },
        component: () => import('@/views/prometheus/portal/MaintenancePage.vue')
    },
];

export default routes;