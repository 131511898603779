<template>
    <div class="battery-lvl">
        <span class="battery-lvl__text" v-if="showText">{{batteryLvl}}</span>
        <SvgIcon class="battery-lvl__icon" :name="'battery-lvl-' + lvl" :style="{color: lvlColor[lvl]}"/>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "UnitsList_unit__status_battery",
    components: {},
    props: {
        unitId: [String, Number],
        showText: {
            type: Boolean,
            default: () => false
        }
    },
    data() {
        return {
            lvlColor: {
                100: '#28B25F',
                75: '#28B25F',
                50: '#FF9211',
                25: '#FF403D',
                0: '#AAAAAA',
            },
        }
    },
    computed: {
        ...mapGetters([
            "unitsLmsgsByIds"
        ]),
        unitLmsg() {
            return this.unitsLmsgsByIds[this.unitId]
        },
        battery() {
            return this.unitLmsg?.sensors?.battery
        },
        batteryLvl() {
            return this.battery?.text || ''
        },
        lvl() {
            let lvl = this.battery?.value
            if(lvl == 100) return 100
            else if(lvl >= 75) return 75
            else if(lvl >= 50) return 50
            else if(lvl >= 25) return 25
            else return 25
        }
    }

}
</script>

<style lang="scss" scoped>
.battery-lvl{
    display: flex;
    align-items: center;
    gap: 10px;
    &__text {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Inter, sans-serif;
        @include font(14px, normal, 500, none, normal, #336190);
        white-space: nowrap;
    }
    &__icon {
        padding-top: 4px;
    }
}
</style>