const RESOURCE_NAME = '/access';
import axios from "@/api/http-auth";
import config from "@/config";
const baseURL = config.api.baseURL

export default () => ({
    getAllObjects(userId, objectName, params) {
        let url = `${RESOURCE_NAME}/${objectName}`
        if(userId > ''){
            url = `/users/${userId}`+url
        }
        return axios.get(url, {params, baseURL});
    },
    setAllObjects(userId, objectName, data, params) {
        let url = `${RESOURCE_NAME}/${objectName}`
        if(userId > ''){
            url = `/users/${userId}`+url
        }
        return axios.post(url, data, {params, baseURL});
    },
    getObjectById(userId, objectName, objectId, params) {
        let url = `${RESOURCE_NAME}/${objectName}/${objectId}`
        if(userId > ''){
            url = `/users/${userId}`+url
        }
        return axios.get(url, {params, baseURL});
    },
    setObjectById(userId, objectName, objectId, data, params) {
        let url = `${RESOURCE_NAME}/${objectName}/${objectId}`
        if(userId > ''){
            url = `/users/${userId}`+url
        }
        return axios.post(url, data, {params, baseURL});
    },
});