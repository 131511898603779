const RESOURCE_NAME = '/address';
import axios from "@/api/http-auth";
import config from "@/config";
const baseURL = config.api.baseURL

export default () => ({
    searchAddress(params) {
        let url = `${RESOURCE_NAME}/search`
        return axios.get(url, {params, baseURL});
    },
    searchAddressPlace(id, params) {
        let url = `${RESOURCE_NAME}/place/${id}`
        return axios.get(url, {params, baseURL});
    },
    eta(data, params) {
        // {
        //     latlng_start:[float,float]
        //     latlng_end:[float,float]
        //
        //     latlng_start[0]:float
        //     latlng_start[1]:float
        //     latlng_end[0]:float
        //     latlng_end[1]:float
        // }
        let url = `${RESOURCE_NAME}/eta`
        return axios.post(url, data, {params, baseURL});
    },
});