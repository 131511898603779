<template>
    <div class="symbol__image-status flex align-items-canter flex-gap-5" :class="statusInfo.icon_class">
        <SvgIcon class="icon" :name="statusInfo.icon"/>
        <span class="unit-indicators__text" v-if="text">{{ $te('Unit.signal.' + statusInfo.text) ? $t('Unit.signal.' + statusInfo.text) : statusInfo.text }}</span>
    </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
    setup() {
        return {
            statuses: {
                not_signal: {
                    bg_icon: '',
                    icon_class: 'connection_not-signal',
                    icon: 'camera-offline',
                    text: '-'
                },
                online: {
                    bg_icon: '',
                    icon_class: 'connection_status-online',
                    icon: 'camera-online',
                    text: 'Online'
                },
                offline: {
                    bg_icon: '',
                    icon_class: 'connection_status_not-online-long-time',
                    icon: 'camera-offline',
                    text: 'Offline'
                },
                standby: {
                    bg_icon: '',
                    icon_class: 'connection_status_not-online-recently',
                    icon: 'camera-online',
                    text: 'Recently'
                },
            },
        }
    },
    name: 'UnitInfo_indicator_camera',
    props: {
        unitId: [String, Number],
        text: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters([
            "getVideoStatusesByUnitsIds",
        ]),
        status() {
            return this.getVideoStatusesByUnitsIds[this.unitId]?.status || 'not_signal'
        },
        statusInfo() {
            return this.statuses[this.status] || this.statuses['not_signal']
        },
    },
    updated: function () {
        // console.log(`UnitInfo_indicator_camera ${this.unitId} updated`);
    },
}
</script>